import React from 'react';
import { IUser } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import UserList from './UserList';

export interface IProps {
  currentUser?: IUser | null;
  error?: ErrorType;
  isLoading: boolean;
  users: IUser[];
  onClose: () => void;
  onSyncUsers: () => void;
  onStopSyncingUsers: () => void;
}

export default function UserListModalContent({ onClose, error, isLoading, users, ...otherProps }: IProps) {
  let title: string;

  if (error || isLoading || users.length === 0) {
    title = 'Members';
  } else if (users.length === 1) {
    title = '1 Member';
  } else {
    title = `${users.length} Members`;
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <UserList error={error} isLoading={isLoading} users={users} {...otherProps} />
      </div>
    </div>
  );
}