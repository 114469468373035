import React from 'react';
import { IUser } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import ErrorMessage from './ErrorMessage';
import UserAvatar from './UserAvatar';

export interface IProps {
  currentUser?: IUser | null;
  error?: ErrorType;
  isLoading: boolean;
  users: IUser[];
  onSyncUsers?: () => void;
  onStopSyncingUsers?: () => void;
}

export default class UserList extends React.PureComponent<IProps> {

  public componentDidMount() {
    const { onSyncUsers } = this.props;
    if (onSyncUsers) {
      onSyncUsers();
    }
  }

  public componentWillUnmount() {
    const { onStopSyncingUsers } = this.props;
    if (onStopSyncingUsers) {
      onStopSyncingUsers();
    }
  }

  public render() {
    const { currentUser, error, isLoading } = this.props;

    if (error) {
      return (
        <div className="user-list">
          <ErrorMessage error={error} doAction={this.props.onSyncUsers} />
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="user-list">
          {this.renderUserPlaceholder()}
          {this.renderUserPlaceholder()}
          {this.renderUserPlaceholder()}
          {this.renderUserPlaceholder()}
        </div>
      );
    }

    const users = this.props.users.map(user => this.renderUser(user, user.id === (currentUser && currentUser.id)));

    return (
      <div className="user-list">
        {users.length > 0 ? users : this.renderNoUsers()}
      </div>
    );
  }

  private renderUser(user: IUser, isCurrentUser: boolean) {
    return (
      <div className="media mb-2" key={user.id}>
        <UserAvatar theme={Theme.LIGHT} avatarName={user.avatarName} avatarUrl={user.avatarUrl} />
        <div className="media-body ml-2 align-self-center">
          <span>{user.name}</span>
          {isCurrentUser && <span className="text-muted"> (you)</span>}
        </div>
      </div>
    );
  }

  private renderUserPlaceholder() {
    return (
      <div className="media mb-2">
        <UserAvatar theme={Theme.LIGHT} />
        <div className="media-body ml-2 align-self-center">
          <div className="placeholder-background" style={{ width: '10rem', height: '1rem' }} />
        </div>
      </div>
    );
  }

  private renderNoUsers() {
    return <p>There aren't any members online.</p>;
  }
}