import { connect } from 'react-redux';
import AppHeader, { IProps as IAppHeaderProps } from '../components/AppHeader';
import { IStoreState } from '../reducers';

function mapStateToProps({ currentUser, currentUserUi, online }: IStoreState): IAppHeaderProps {
  const { isLoadingUserData } = currentUserUi;

  return { isLoadingUserData, online, user: currentUser };
}

export default connect(mapStateToProps)(AppHeader);