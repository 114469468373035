import { pick } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { addRoomRequest, changeRoomForm, initRoomForm, RoomsAction, updateRoomRequest } from '../actions';
import { IRoom } from '../chat/Chat';
import RoomForm, { IProps as IRoomFormProps } from '../components/RoomForm';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<IRoomFormProps, 'roomProperties' | 'isSubmitting' | 'error'> & { room: IRoom | undefined };
export interface IDispatchProps { dispatch: Dispatch<RoomsAction> };
export interface IOwnProps { roomId?: string };

function mapStateToProps({ roomForm, rooms }: IStoreState, { roomId }: IOwnProps): IStateProps {
  let room: IRoom | undefined;
  const { error, isSubmitting, roomProperties } = roomForm;

  if (roomId) {
    room = rooms[roomId];
  }

  return {
    error,
    isSubmitting,
    room,
    roomProperties
  };
}

function mapDispatchToProps(dispatch: Dispatch<RoomsAction>): IDispatchProps {
  return { dispatch };
}

function mergeProps(stateProps: IStateProps, { dispatch }: IDispatchProps, { roomId }: IOwnProps): IRoomFormProps {
  const { room, ...restStateProps } = stateProps;

  return {
    ...restStateProps,
    onInit: () => {
      if (roomId && room) {
        // init roomForm state with room data if editing room
        dispatch(initRoomForm(pick(room, ['name', 'description', 'imageUrl'])));
      }
    },
    onInputChange: roomProperties => dispatch(changeRoomForm(roomProperties)),
    onSubmit: roomProperties => {
      if (roomId) {
        dispatch(updateRoomRequest(roomId, roomProperties));
      } else {
        dispatch(addRoomRequest(roomProperties));
      }
    }
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRoomFormProps, IStoreState>(mapStateToProps, mapDispatchToProps, mergeProps)(RoomForm);