import firebase from 'firebase/app';
import 'firebase/database';
import { pick } from 'lodash';
import Chat, { EventType, IMessage, IRoom, IUser, MessageType } from './Chat';

interface IPresenceBit {
  ref: firebase.database.Reference;
  onlineValue: object | null;
  offlineValue: object | null;
}

enum ChatLocation {
  ROOMS = 'rooms',
  ROOM_USERS = 'room-users',
  ROOM_MESSAGES = 'room-messages',
  USERS = 'users',
  USERS_ONLINE = 'users-online',
  MODERATORS = 'moderators'
}

class FirebaseChat extends Chat {

  public user: IUser | null = null;

  public inRoom: IRoom | null = null;

  private ref: firebase.database.Reference | null = null;

  private presenceBits:
    Map<string, IPresenceBit> = new Map();

  get connectedRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.root.child('.info/connected');
  }

  get roomsRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.ROOMS);
  }

  get roomUsersRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.ROOM_USERS);
  }

  get roomMessagesRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.ROOM_MESSAGES);
  }

  get usersRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.USERS);
  }

  get usersOnlineRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.USERS_ONLINE);
  }

  get moderatorsRef(): firebase.database.Reference {
    if (!this.ref) {
      throw new Error();
    }
    return this.ref.child(ChatLocation.MODERATORS);
  }

  /**
   * Connect to firebase database reference
   *  
   * @param ref firebase reference
   */
  public connect(ref: firebase.database.Reference): FirebaseChat {
    this.ref = ref;
    this.connectedRef.on('value', this.onConnectedStateChange, this);
    return this;
  }

  /** Disconnect from firebase */
  public disconnect(): FirebaseChat {
    this.connectedRef.off('value', this.onConnectedStateChange, this);
    this.ref = null;
    return this;
  }

  public async syncUsersOnline(): Promise<{ [index: string]: IUser | undefined }> {
    const users = (await this.usersOnlineRef.once('value')).val() || {};

    this.usersOnlineRef.on('child_added', this.onUserOnlineAdded, this.onError, this);
    this.usersOnlineRef.on('child_removed', this.onUserOnlineDeleted, this.onError, this);

    return users;
  }

  public stopSyncingUsersOnline(): FirebaseChat {
    this.usersOnlineRef.off('child_added', this.onUserOnlineAdded, this);
    this.usersOnlineRef.off('child_removed', this.onUserOnlineDeleted, this);

    return this;
  }

  public async syncRooms(): Promise<{ [index: string]: IRoom | undefined }> {
    let rooms: { [id: string]: IRoom };

    try {
      rooms = (await this.roomsRef.once('value')).val() || {};
    } catch (e) {
      this.eventEmitter.emit(EventType.AUTH_REQUIRED);
      throw e;
    }

    this.eventEmitter.emit(EventType.ROOMS_SYNC, rooms);

    this.roomsRef.on('child_added', this.onRoomAdded, this.onError, this);
    this.roomsRef.on('child_changed', this.onRoomUpdated, this.onError, this);
    this.roomsRef.on('child_removed', this.onRoomDeleted, this.onError, this);

    return rooms;
  }

  public stopSyncingRooms(): FirebaseChat {
    this.roomsRef.off('child_added', this.onRoomAdded, this);
    this.roomsRef.off('child_changed', this.onRoomUpdated, this);
    this.roomsRef.off('child_removed', this.onRoomDeleted, this);

    return this;
  }

  public async getRoom(roomId: string): Promise<IRoom | null> {
    return (await this.roomsRef.child(roomId).once('value')).val();
  }

  public async addRoom(room: Partial<IRoom>): Promise<IRoom> {
    if (!this.user) {
      throw new Error('Cannot add room, user not set.');
    }

    const newRoomRef = this.roomsRef.push();
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    const newRoom: IRoom = {
      ...room,
      createdAt: timestamp,
      createdByUser: pick(this.user, ['id', 'name', 'avatarName', 'avatarUrl']),
      id: newRoomRef.key as string,
      updatedAt: timestamp
    } as IRoom;

    await newRoomRef.set(newRoom);

    return newRoom;
  }

  /**
   * Update room properties. Set a property value to null to delete a property.
   * 
   * @param roomId 
   * @param propertiesToUpdate 
   */
  public async updateRoom(roomId: string, propertiesToUpdate: Partial<{ [P in keyof IRoom]: IRoom[P] | null }>): Promise<IRoom> {
    if (!this.user) {
      throw new Error('Cannot update room, user not set.');
    }

    const room = await this.getRoom(roomId);

    if (!room) {
      throw new Error('Cannot update room, room does not exist.');
    }

    propertiesToUpdate = {
      ...propertiesToUpdate,
      updatedAt: firebase.database.ServerValue.TIMESTAMP
    } as IRoom;
    
    await this.roomsRef.child(roomId).update(propertiesToUpdate);

    return (await this.roomsRef.child(roomId).once('value')).val();
  }

  public async deleteRoom(roomId: string): Promise<void> {
    const hasUsers = (await this.roomUsersRef.child(roomId).once('value')).hasChildren();

    if (hasUsers) {
      throw new Error('Cannot delete room, room not empty.');
    }

    await Promise.all([
      this.roomUsersRef.child(roomId).remove(),
      this.roomMessagesRef.child(roomId).remove()
    ]);

    return this.roomsRef.child(roomId).remove();
  }

  /**
   * This method can be called when user is not set but need to be called again after user is set to setup presence bit.
   * 
   * @param roomId 
   */
  public async enterRoom(roomId: string): Promise<void> {
    const room = await this.getRoom(roomId);

    if (!room) {
      throw new Error('Cannot enter room, room does not exist.');
    }

    if (this.user) {
      // skip if user is already in this room
      if ((await this.roomUsersRef.child(roomId).child(this.user.id).once('value')).exists()) {
        return;
      }

      await this.addPresenceBit(this.roomUsersRef.child(roomId).child(this.user.id), this.user, null);

      await this.sendMessage(roomId, `${this.user.name}`, MessageType.SYSTEM_ENTER_ROOM);
    }

    this.inRoom = room;

    this.eventEmitter.emit(EventType.ROOM_ENTER, room);

    return;
  }

  public async exitRoom(roomId: string): Promise<void> {
    if (this.user) {
      await this.removePresenceBit(this.roomUsersRef.child(roomId).child(this.user.id));
      await this.sendMessage(roomId, `${this.user.name}`, MessageType.SYSTEM_EXIT_ROOM);
    }
    this.eventEmitter.emit(EventType.ROOM_EXIT, this.inRoom);
    this.inRoom = null;
  }

  public inviteUserToRoom(): Promise<void> {
    return Promise.resolve();
  }

  public acceptInvitationToRoom(): Promise<void> {
    return Promise.resolve();
  }

  public async syncRoomMessages(roomId: string): Promise<{ [index: string]: IMessage | undefined }> {
    let messages: { [id: string]: IMessage };

    const messagesQuery = this.roomMessagesRef
      .child(roomId)
      .orderByKey()
      .limitToLast(50); // TODO: support pagination

    try {
      const messagesSnapshot = await messagesQuery.once('value');
      messages = messagesSnapshot.val() || {};
    } catch (e) {
      this.eventEmitter.emit(EventType.AUTH_REQUIRED);
      throw e;
    }

    this.eventEmitter.emit(EventType.ROOM_MESSAGES_SYNC, messages);

    messagesQuery.on('child_added', this.onRoomMessageAdded, this.onError, this);
    messagesQuery.on('child_changed', this.onRoomMessageUpdated, this.onError, this);
    messagesQuery.on('child_removed', this.onRoomMessageDeleted, this.onError, this);

    return messages;
  }

  public stopSyncingRoomMessages(roomId: string): FirebaseChat {
    this.roomMessagesRef.child(roomId).off('child_added', this.onRoomMessageAdded, this);
    this.roomMessagesRef.child(roomId).off('child_changed', this.onRoomMessageUpdated, this);
    this.roomMessagesRef.child(roomId).off('child_removed', this.onRoomMessageDeleted, this);

    return this;
  }

  /**
   * Send message.
   * 
   * @param roomId 
   * @param content 
   * @param type Message type
   * @param onBeforeSend Function that will be called with generated message id before sending. This is useful if setting message sending state is required.
   */
  public async sendMessage(
    roomId: string,
    content: string,
    type: MessageType = MessageType.USER,
    onBeforeSend?: (messageId: string) => void
  ): Promise<IMessage> {
    if (!this.user) {
      throw new Error('Cannot send message, user not set.');
    }

    const messageId = this.roomMessagesRef.child(roomId).push().key as string;
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    const message: IMessage = {
      content,
      createdAt: timestamp,
      id: messageId,
      type,
      updatedAt: timestamp
    } as IMessage;
    const updates: Record<string, any> = {
      [`${ChatLocation.ROOM_MESSAGES}/${roomId}/${messageId}`]: message,
    };

    if (type === MessageType.USER) {
      message.fromUser = pick(this.user, ['id', 'name', 'avatarName', 'avatarUrl']);
      updates[`${ChatLocation.ROOMS}/${roomId}/lastMessage`] = pick(message, ['content', 'fromUser', 'type']);
      updates[`${ChatLocation.ROOMS}/${roomId}/updatedAt`] = timestamp;
    }

    if (onBeforeSend) {
      onBeforeSend(messageId);
    }

    await (this.ref as firebase.database.Reference).update(updates);

    return message;
  }

  public updateMessage(messageId: string, messageContent: string): Promise<IMessage> {
    return Promise.resolve({ id: messageId, type: MessageType.USER, content: messageContent });
  }

  public deleteMessage(messageId: string): Promise<void> {
    return Promise.resolve();
  }

  public async syncRoomUsers(roomId: string): Promise<{ [index: string]: IUser | undefined }> {
    const roomUsersRef = this.roomUsersRef.child(roomId);
    const users = (await roomUsersRef.once('value')).val() || {};

    roomUsersRef.on('child_added', this.onRoomUserAdded, this.onError, this);
    roomUsersRef.on('child_removed', this.onRoomUserDeleted, this.onError, this);

    return users;
  }

  public stopSyncingRoomUsers(roomId: string): FirebaseChat {
    const roomUsersRef = this.roomUsersRef.child(roomId);

    roomUsersRef.off('child_added', this.onRoomUserAdded, this);
    roomUsersRef.off('child_removed', this.onRoomUserDeleted, this);

    return this;
  }

  public async getUser(userId: string): Promise<IUser | null> {
    return (await this.usersRef.child(userId).once('value')).val();
  }

  /**
   * Updates the user record in the database. Sets #user with the updated user record in the database.
   * 
   * @param userProperties User properties to update
   */
  public async updateUser(userProperties: Partial<IUser>): Promise<void> {
    if (!this.user) {
      throw new Error('Cannot update user. User not set.');
    }

    await this.usersRef.child(this.user.id).update(userProperties);
    this.user = await this.getUser(this.user.id);
  }

  public async setUser(user: IUser): Promise<void> {
    if (this.user) {
      throw new Error('Cannot set user. Unset current user before setting a new user.');
    }

    this.user = user;
    await this.usersRef.child(user.id).update(user);
    await this.addPresenceBit(this.usersOnlineRef.child(user.id), this.user, null);

    if (this.inRoom) {
      return this.enterRoom(this.inRoom.id);
    }
  }

  public async unsetUser(): Promise<void> {
    if (this.user) {
      if (this.inRoom) {
        this.exitRoom(this.inRoom.id);
      }

      await this.removePresenceBit(this.usersOnlineRef.child(this.user.id));

      this.user = null;
      this.eventEmitter.emit(EventType.USER_UPDATE, null);
    }
  }

  /**
   * Set value and onDisconnect value for ref. Also keep track of it so they can be
   * added if we disconnect then reconnect.
   *
   * @param ref
   * @param onlineValue
   * @param offlineValue
   */
  private async addPresenceBit(ref: firebase.database.Reference,
                               onlineValue: object | null,
                               offlineValue: object | null): Promise<void> {
    await Promise.all([
      ref.onDisconnect().set(offlineValue),
      ref.set(onlineValue)
    ]);

    this.presenceBits.set(ref.toString(), { ref, onlineValue, offlineValue });
  }

  /**
   * Cancel onDisconnect set or update events for `ref` and remove it from tracking presence bits.
   *
   * @param ref
   * @param value
   */
  private async removePresenceBit(ref: firebase.database.Reference, value: object | null = null): Promise<void> {
    await Promise.all([
      ref.onDisconnect().cancel(),
      ref.set(value)
    ]);

    this.presenceBits.delete(ref.toString());
  }

  private onConnectedStateChange(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    if (snapshot.val()) {
      // We are connected (or reconnected). Reapply presence bits and call connected listener.
      this.presenceBits.forEach(({ref, onlineValue, offlineValue }: IPresenceBit) => {
        return Promise.all([
          ref.onDisconnect().set(offlineValue),
          ref.set(onlineValue)
        ]);
      });
      this.eventEmitter.emit(EventType.ONLINE);
    } else {
      this.eventEmitter.emit(EventType.OFFLINE);
    }
  }

  private onError(this: FirebaseChat, e: Error) {
    this.eventEmitter.emit(EventType.AUTH_REQUIRED);
  }

  private onUserOnlineAdded(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.USER_ONLINE_ADD, snapshot.val());
  }

  private onUserOnlineDeleted(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.USER_ONLINE_DELETE, snapshot.val());
  }

  private onRoomUserAdded(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_USER_ADD, snapshot.val());
  }

  private onRoomUserDeleted(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_USER_DELETE, snapshot.val());
  }

  private onRoomAdded(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_ADD, snapshot.val());
  }

  private onRoomUpdated(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_UPDATE, snapshot.val());
  }

  private onRoomDeleted(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_DELETE, snapshot.val());
  }

  private onRoomMessageAdded(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_MESSAGE_ADD, snapshot.val());
  }

  private onRoomMessageUpdated(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_MESSAGE_UPDATE, snapshot.val());
  }

  private onRoomMessageDeleted(this: FirebaseChat, snapshot: firebase.database.DataSnapshot | null) {
    if (!snapshot) {
      return;
    }
    this.eventEmitter.emit(EventType.ROOM_MESSAGE_DELETE, snapshot.val());
  }
}

export default FirebaseChat;