import { combineReducers } from 'redux';
import { currentUser, ICurrentUserState } from './currentUser';
import { online } from './online';
import { IRoomFormState, roomForm } from './roomForm';
import { IRoomMessagesState, roomMessages } from './roomMessages';
import { IRoomsState, rooms } from './rooms';
import {
  currentUserUi,
  deleteRoomModalUi,
  ICurrentUserUiState,
  IDeleteRoomModalUiState,
  IRoomListUiState,
  IRoomMessageListUiState,
  IRoomMessagesUiState,
  IRoomsUiState,
  IRoomUsersModalsUiState,
  IUserOnlineListUiState,
  roomListUi,
  roomMessageListUi,
  roomMessagesUi,
  roomsUi,
  roomUsersModalsUi,
  userOnlineListUi
} from './ui';
import { IUserFormState, userForm } from './userForm';
import { IRoomUsersState, IUsersOnlineState, roomUsers, usersOnline } from './users';

export interface IStoreState {
  currentUser: ICurrentUserState;
  online: boolean;
  roomForm: IRoomFormState;
  roomMessages: IRoomMessagesState;
  roomUsers: IRoomUsersState;
  rooms: IRoomsState;
  userForm: IUserFormState;
  usersOnline: IUsersOnlineState;

  // ui state
  currentUserUi: ICurrentUserUiState;
  deleteRoomModalUi: IDeleteRoomModalUiState;
  roomListUi: IRoomListUiState;
  roomMessageListUi: IRoomMessageListUiState;
  roomMessagesUi: IRoomMessagesUiState;
  roomUsersModalsUi: IRoomUsersModalsUiState;
  roomsUi: IRoomsUiState;
  userOnlineListUi: IUserOnlineListUiState;
}

export default combineReducers<IStoreState>({
  currentUser,
  currentUserUi,
  deleteRoomModalUi,
  online,
  roomForm,
  roomListUi,
  roomMessageListUi,
  roomMessages,
  roomMessagesUi,
  roomUsers,
  roomUsersModalsUi,
  rooms,
  roomsUi,
  userForm,
  userOnlineListUi,
  usersOnline
});