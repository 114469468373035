import React from 'react';
import Chat from '../chat/Chat';
import FirebaseChat from '../chat/FirebaseChat';

export const ChatContext = React.createContext<Chat>(new FirebaseChat());

export function withChat<Props>(Component: React.ComponentType<Partial<Props> & { chat: Chat }>) {
  return function ChatComponent(props: Props) {
    return (
      <ChatContext.Consumer>
        {(chatInstance: Chat) => <Component {...props} chat={chatInstance} />}
      </ChatContext.Consumer>
    );
  };
}