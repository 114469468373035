import React from 'react';
import { ErrorType } from '../constants/error';

export interface IProps {
  error: ErrorType;
  doAction?: () => void;
}

function ErrorMessage({ error, doAction }: IProps) {
  let message = '';
  let action = '';

  // TODO: i18n error message and action
  switch (error) {
    case ErrorType.NOT_AUTHORISED:
      message = 'Not authorised.';
      break;
    case ErrorType.NETWORK_ERROR:
      message = 'There is no Internet connection. Try again later.';
      break;
    case ErrorType.AUTH_ERROR:
      message = 'Failed to authenticate. Please try again.';
      break;
    case ErrorType.AW_SNAP:
      message = '(・_・ヾ Something went wrong.';
      action = 'Try again';
      break;
    case ErrorType.MAXIMUM_NUMBER_OF_ROOMS_REACHED:
      message = 'Maximum number of rooms reached.';
      break;
    case ErrorType.ROOM_NOT_AVAILABLE:
      message = 'The room is not available.';
      break;
    case ErrorType.FAILED_TO_DELETE_ROOM_NOT_EMPTY:
      message = 'There are currently users in the room. Try again when the room is empty.';
      break;
    default:
      throw new Error(`Unknown error type: ${error}.`);
  }

  return (
    <p>
      {message}
      {doAction && action &&
        <button type="button" className="btn btn-link align-baseline" onClick={doAction}>{action}</button>}
    </p>
  );
}

export default ErrorMessage;