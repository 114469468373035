import { omit, pick } from 'lodash';
import React from 'react';
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING
} from 'react-transition-group/Transition';

export interface IProps {
  isOpen: boolean;
  navbar: boolean;
  className?: string;

  // transition props
  appear?: boolean;
  enter?: boolean;
  exit?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;

  // child props
  [prop: string]: any;
}

export interface IState {
  height: number | null;
}

const TransitionPropKeys = [
  'nodeRef',
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout'
];

const Duration = { ENTER: 250, EXIT: 200 };

const TransitionStatusClass = {
  [ENTERING]: 'collapsing',
  [ENTERED]: 'collapse show',
  [EXITING]: 'collapsing',
  [EXITED]: 'collapse'
};

function getTransitionClass(state: string) {
  return TransitionStatusClass[state] || TransitionStatusClass[EXITED];
}

function getHeight(node: HTMLElement | null) {
  return node?.scrollHeight || 0;
}

class Collapse extends React.PureComponent<IProps, IState> {
  public state: IState = {
    height: null
  };

  private content = React.createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);

    [
      'onEntering',
      'onEntered',
      'onExit',
      'onExiting',
      'onExited'
    ].forEach(name => this[name] = this[name].bind(this));
  }

  public render() {
    const { children, isOpen, className, navbar, ...otherProps } = this.props;
    const transitionProps = pick(otherProps, TransitionPropKeys);
    const childProps = omit(otherProps, TransitionPropKeys);
    const { height } = this.state;

    return (
      <Transition
        {...transitionProps}
        nodeRef={this.content}
        in={isOpen}
        timeout={{ enter: Duration.ENTER, exit: Duration.EXIT }}
        onEntered={this.onEntered}
        onEntering={this.onEntering}
        onExit={this.onExit}
        onExited={this.onExited}
        onExiting={this.onExiting}
      >
        {state => {
          const collapseClass = getTransitionClass(state);
          const style: React.CSSProperties = height === null ? {} : { height };

          if (state === ENTERING) {
            style.transitionDuration = `${Duration.ENTER}ms`;
          } else if (state === EXITING) {
            style.transitionDuration = `${Duration.EXIT}ms`;
          }

          return (
            <div
              {...childProps}
              className={`${className ? className : ''} ${navbar ? 'navbar-collapse' : ''} ${collapseClass}`}
              style={style}
              ref={this.content}
            >
              {children}
            </div>
          );
        }}
      </Transition>
    );
  }

  private onEntering() {
    this.setState({ height: getHeight(this.content.current) });
  }
  
  private onEntered() {
    this.setState({ height: null });
  }

  private onExit() {
    this.setState({ height: getHeight(this.content.current) });
  }
  
  private onExiting() {
    // this is to force a reflow
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.content.current?.scrollTop;
    this.setState({ height: 0 });
  }
  
  private onExited() {
    this.setState({ height: null });
  }
}

export default Collapse;
