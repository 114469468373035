import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './App.scss';

import SignInPage from '../containers/SignInPage';
import AddRoomPage from './AddRoomPage';
import EditRoomPage from './EditRoomPage';
import HomePage from './HomePage';
import RoomListPage from './RoomListPage';
import RoomPage from './RoomPage';
import Router from './Router';
import SettingsPage from './SettingsPage';
import UsersOnlinePage from './UsersOnlinePage';

export default () => (
  <main role="main">
    <Router>
      <Switch>
        <Route exact={true} path="/" component={HomePage} />
        <Route path="/rooms/new" component={AddRoomPage} />
        <Route path="/rooms/:id/edit" component={EditRoomPage} />
        <Route path="/members" component={UsersOnlinePage} />
        <Route path="/sign-in" component={SignInPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path={['/rooms', '/rooms/:id']} component={RoutesTransitionGroup} />
        <Route render={() => <div>404</div>} />
      </Switch>
    </Router>
  </main>
);

class RoutesTransitionGroup extends React.PureComponent<RouteComponentProps<{ id?: string }>> {
  private list = React.createRef<HTMLDivElement>();
  private page = React.createRef<HTMLDivElement>();

  public componentDidUpdate(prevProps: RouteComponentProps<{}>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return (
      <>
        <Route key="/rooms" exact path="/rooms">
          {({ match }) => (
            <CSSTransition
              nodeRef={this.list}
              in={match !== null}
              timeout={350}
              classNames="transition-list"
              unmountOnExit
            >
              <div className="transition-list" ref={this.list}>
                <RoomListPage />
              </div>
            </CSSTransition>
          )}
        </Route>
        <Route key="/rooms/:id" exact path="/rooms/:id">
          {({ match }) => (
            <CSSTransition
              nodeRef={this.page}
              in={match !== null}
              timeout={350}
              classNames="transition-page"
              unmountOnExit
            >
              <div className="transition-page" ref={this.page}>
                <RoomPage roomId={match?.params.id} />
              </div>
            </CSSTransition>
          )}
        </Route>
      </>
    );
  }
}
