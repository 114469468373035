import { IGetCurrentUserSuccess, IUpdateCurrentUserSuccess } from '../actions/users';
import { IUser } from '../chat/Chat';
import { GET_CURRENT_USER_SUCCESS, UPDATE_CURRENT_USER_SUCCESS } from '../constants/actionType';

export type ICurrentUserState = IUser | null;

export function currentUser(state: ICurrentUserState = null, action: IGetCurrentUserSuccess | IUpdateCurrentUserSuccess): ICurrentUserState {
  switch(action.type) {
    case GET_CURRENT_USER_SUCCESS:
    case UPDATE_CURRENT_USER_SUCCESS:
      return action.user;
    default:
      return state;
  }
}