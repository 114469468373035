import React from 'react';
import { IRoom } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import ErrorMessage from './ErrorMessage';

export interface IProps {
  error?: ErrorType;
  isLoading: boolean;
  roomData?: IRoom;
  onClose: () => void;
  onDeleteRoom: (roomId: string) => void;
}

class DeleteRoomModal extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.deleteRoom = this.deleteRoom.bind(this);
  }

  public render() {
    const { error, isLoading, roomData = { name: '' }, onClose } = this.props;

    const modalBody = error ? (
      <div className="modal-body">
        <ErrorMessage error={error} />
      </div >
    ) : (
      <div className="modal-body">
        <p>Permanently delete the <b>{roomData.name}</b> room.</p>
      </div >
    );

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{error ? 'Failed to delete room' : 'Delete room?'}</h5>
        </div>
        {modalBody}
        <div className="modal-footer">
          <button className="btn btn-link" disabled={isLoading} onClick={onClose}>Cancel</button>
          <button className="btn btn-link" disabled={isLoading} onClick={this.deleteRoom}>Delete</button>
        </div>
      </div>
    );
  }

  private deleteRoom() {
    const { roomData, onDeleteRoom } = this.props;

    if (roomData) {
      onDeleteRoom(roomData.id);
    }
  }
}

export default DeleteRoomModal;