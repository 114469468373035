import React from 'react';
import AppHeader from '../containers/AppHeader';
import RoomForm from '../containers/RoomForm';

export default () => (
  <React.Fragment>
    <AppHeader />
    <div className="container-xl my-3">
      <RoomForm />
    </div>
  </React.Fragment>
);