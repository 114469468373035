import { History } from 'history';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { IChangeRoute } from '../actions';
import { CHANGE_ROUTE } from '../constants/actionType';
import { IStoreState } from '../reducers';

export const changeRouteEpic: Epic<Action, Action, IStoreState, { history: History }> = (action$, state$, { history }) => {
  return action$.pipe(
    ofType<Action ,IChangeRoute>(CHANGE_ROUTE),
    tap(action => history.push(action.path)),
    ignoreElements()
  );
}