import React from 'react';
import { IRoom } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import DeleteRoomModalContent from './DeleteRoomModalContent';
import Modal from './Modal';

export interface IProps {
  error?: ErrorType;
  isLoading: boolean;
  isOpen: boolean;
  roomData?: IRoom;
  onClose: () => void;
  onDeleteRoom: (roomId: string) => void;
}

function DeleteRoomModal(props: IProps) {

  const { isOpen, onClose, ...otherProps } = props;

  return (
    <Modal isCentered={true} isOpen={isOpen} onRequestClose={onClose}>
      <DeleteRoomModalContent onClose={onClose} {...otherProps} />
    </Modal>
  );
}

export default DeleteRoomModal;