import React from 'react';
import { MessageType } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import './SystemMessage.scss';

export interface IProps {
  content: string;
  theme: Theme;
  type: MessageType;
}

export default (props: IProps) => {
  const { content, theme, type } = props;
  const themeClass = theme === Theme.LIGHT ? 'system-message--light' : 'system-message--dark';
  let systemMessage = '';

  // TODO: i18n system message
  switch(type) {
    case MessageType.SYSTEM_ENTER_ROOM:
      systemMessage = 'joined';
      break;
    case MessageType.SYSTEM_EXIT_ROOM:
      systemMessage = 'left';
      break;
  }

  return (
    <div className={`system-message ${themeClass}`}>
      <div className="system-message-arrow" />
      <div className="system-message-arrow" />
      {`${content} ${systemMessage}`}
    </div>
  );
};