import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { ErrorType } from '../constants/error';
import { EMAIL_FOR_SIGN_IN } from '../constants/key';
import AppHeader from '../containers/AppHeader';
import ConfirmEmailToSignIn from './ConfirmEmailToSignIn';
import ErrorMessage from './ErrorMessage';
import SignInWithEmailLink from './SignInWithEmailLink';
import SignInWithGoogle from './SignInWithGoogle';

export interface IProps {
  error?: ErrorType;
  emailLinkSent?: string;
  isLoadingUserCredential: boolean;
  isLoadingUserData: boolean;
  isSignedIn: boolean;
  onGetRedirectResult: () => void;
  onSendEmailLink: (email: string) => void;
  onSignInWithEmailLink: (email: string) => void;
  onSignInWithGoogle: () => void;
  onSignOut: () => void;
}

class SignInPage extends React.PureComponent<IProps> {

  private isSignInWithEmailLink: boolean = false;
  private emailForSignIn: string | null = null;

  public constructor(props: IProps) {
    super(props);

    this.isSignInWithEmailLink = firebase.auth().isSignInWithEmailLink(window.location.href);
    this.emailForSignIn = window.localStorage.getItem(EMAIL_FOR_SIGN_IN);
  }

  public componentDidMount() {
    if (this.isSignInWithEmailLink) {
      if (this.emailForSignIn) {
        this.props.onSignInWithEmailLink(this.emailForSignIn);
      }
    } else {
      this.props.onGetRedirectResult();
    }
  }

  public render() {
    const { error, emailLinkSent, isLoadingUserCredential, isLoadingUserData, isSignedIn, onSignInWithEmailLink } = this.props;
    let content = null;

    if (error) {
      content = <ErrorMessage error={error} />;
    } else if (isLoadingUserData) {
      content = <p className="text-center">Loading user data...</p>;
    } else if (isSignedIn) {
      content = this.renderSignOut();
    } else if (emailLinkSent) {
      content = <p className="text-center">We sent you a link to sign in. Please check your inbox <strong>{emailLinkSent}</strong>.</p>;
    } else if (this.isSignInWithEmailLink && !this.emailForSignIn) {
      content = <ConfirmEmailToSignIn disabled={isLoadingUserCredential} onSignIn={onSignInWithEmailLink} />
    } else {
      content = this.renderSignIn();
    }

    return (
      <React.Fragment>
        <AppHeader />
        <div className="container-xl my-3">
          {content}
        </div>
      </React.Fragment>
    );
  }

  private renderSignIn() {
    const { isLoadingUserCredential, onSendEmailLink, onSignInWithGoogle } = this.props;

    return (
      <React.Fragment>
        <SignInWithGoogle disabled={isLoadingUserCredential} onSignIn={onSignInWithGoogle} />
        <div className="text-center my-3">or</div>
        <SignInWithEmailLink disabled={isLoadingUserCredential} onSendEmailLink={onSendEmailLink} />
      </React.Fragment>
    );
  }

  private renderSignOut() {
    const { onSignOut } = this.props;

    return (
      <p className="text-center">
        You are signed in.
          <button type="button" className="btn btn-link align-baseline" onClick={onSignOut}>Sign out</button>
      </p>
    );
  }
}

export default SignInPage;