import React from 'react';
import { IMessage, MessageType } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import { IMessageUiState } from '../reducers/ui';
import ChatMessage from './ChatMessage';
import './ChatMessageList.scss';
import ErrorMessage from './ErrorMessage';
import SystemMessage from './SystemMessage';

export interface IProps {
  currentUserId?: string;
  isLoadingMessages: boolean;
  messagesError?: ErrorType;
  messagesUi: { [messageId: string]: IMessageUiState | undefined };
  messages: IMessage[];
  theme: Theme;
  onSyncMessages?: () => void;
  onStopSyncingMessages?: () => void;
}

class ChatMessageList extends React.PureComponent<IProps> {

  public componentDidMount() {
    const { onSyncMessages } = this.props;
    if (onSyncMessages) {
      onSyncMessages();
    }
  }

  public componentWillUnmount() {
    const { onStopSyncingMessages } = this.props;
    if (onStopSyncingMessages) {
      onStopSyncingMessages();
    }
  }

  public render() {
    const { isLoadingMessages, messagesError, theme } = this.props;
    const containerClass = `chat-message-list ${theme === Theme.DARK ? 'text-white' : ''}`;

    if (messagesError) {
      return (
        <div className={`${containerClass} text-center`}>
          <ErrorMessage error={messagesError} doAction={this.props.onSyncMessages} />
        </div>
      );
    }

    if (isLoadingMessages) {
      return (
        <div className={`${containerClass} text-center`}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading messages...</span>
          </div>
        </div>
      );
    }

    return (
      <div className={containerClass}>
        {this.renderMessages()}
      </div>
    );
  }

  private renderMessages() {
    const { currentUserId, messagesUi, messages, theme } = this.props;

    return messages.map(message => {
      const { id, content, fromUser, type } = message;

      if (type !== MessageType.USER) {
        return <SystemMessage key={id} content={content} theme={theme} type={type} />
      }

      const messageUi: IMessageUiState = messagesUi[id] || { isSending: false };
      let alignRight = false;

      if (fromUser) {
        alignRight = !!currentUserId && currentUserId === fromUser.id;
      }

      return <ChatMessage
        key={id}
        alignRight={alignRight}
        content={content}
        fromUser={fromUser}
        isSending={messageUi.isSending}
        theme={theme}
      />;
    });
  }
}

export default ChatMessageList;