import React from 'react';
import ReactModal from 'react-modal';

export interface IProps {
  isCentered?: boolean;
  isOpen: boolean;
  children: JSX.Element | JSX.Element[];
  onRequestClose: () => void;
}

const Duration = { ENTER: 250, EXIT: 200 };

function Modal(props: IProps) {
  const { isCentered, isOpen, children, onRequestClose } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={Duration.EXIT}
      bodyOpenClassName="modal-open"
      overlayClassName={{ base: 'modal', afterOpen: 'modal-after-open', beforeClose: 'modal-before-close' }}
      className={{ base: `modal-dialog modal-dialog-scrollable ${isCentered && 'modal-dialog-centered'}`, afterOpen: 'modal-dialog-after-open', beforeClose: 'modal-dialog-before-close' }}
      onRequestClose={onRequestClose}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;

export const setAppElement = ReactModal.setAppElement;