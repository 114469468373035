import { captureException } from '@sentry/react';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { from, fromEvent, merge, of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AddRoomAction,
  addRoomFailure,
  addRoomSuccess,
  changeRoute,
  closeDeleteRoomModal,
  deleteRoomFailure,
  deleteRoomSuccess,
  GetRoomAction,
  getRoomFailure,
  getRoomSuccess,
  IAddRoomRequest,
  IChangeRoute,
  ICloseDeleteRoomModal,
  IDeleteRoomFailure,
  IDeleteRoomRequest,
  IGetRoomRequest,
  initRoomForm,
  IUpdateRoomRequest,
  RoomFormAction,
  SyncRoomsAction,
  syncRoomsFailure,
  syncRoomsSuccess,
  UpdateRoomAction,
  updateRoomFailure,
  updateRoomSuccess,
  ISyncRoomsSuccess,
  IAddRoomSuccess,
  IUpdateRoomSuccess,
  IDeleteRoomSuccess
} from '../actions';
import Chat, { EventType, IRoom } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { IStoreState } from '../reducers';

export const syncRoomsRequestEpic: Epic<Action, SyncRoomsAction, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType(actionType.SYNC_ROOMS_REQUEST),
    switchMap(() =>
      from(chat.syncRooms()).pipe(
        map(rooms => syncRoomsSuccess(rooms)),
        catchError(e => {
          captureException(e);
          return of(syncRoomsFailure(ErrorType.AW_SNAP));
        })
      )
    )
  );
}

export const syncRoomsSuccessEpic: Epic<Action, IAddRoomSuccess | IUpdateRoomSuccess | IDeleteRoomSuccess, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, ISyncRoomsSuccess>(actionType.SYNC_ROOMS_SUCCESS),
    switchMap(() => 
      merge<IAddRoomSuccess, IUpdateRoomSuccess, IDeleteRoomSuccess>(
        fromEvent<IRoom>(chat, EventType.ROOM_ADD).pipe(
          filter(room => !state$.value.rooms[room.id]),
          map(room => addRoomSuccess(room))
        ),
        fromEvent<IRoom>(chat, EventType.ROOM_UPDATE).pipe(map(room => updateRoomSuccess(room.id, room))),
        fromEvent<IRoom>(chat, EventType.ROOM_DELETE).pipe(map(room => deleteRoomSuccess(room.id)))
      ).pipe(
        takeUntil(action$.pipe(
          ofType(actionType.STOP_SYNCING_ROOMS_REQUEST)
        ))
      )
    )
  );
}

export const stopSyncingRoomsRequestEpic: Epic<Action, Action, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType(actionType.STOP_SYNCING_ROOMS_REQUEST),
    tap(() => chat.stopSyncingRooms()),
    ignoreElements()
  );
}

export const getRoomRequestEpic: Epic<Action, GetRoomAction, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, IGetRoomRequest>(actionType.GET_ROOM_REQUEST),
    mergeMap(action =>
      from(chat.getRoom(action.roomId)).pipe(
        map(room => {
          if (room) {
            return getRoomSuccess(room.id, room);
          } else {
            return getRoomFailure(action.roomId, ErrorType.ROOM_NOT_AVAILABLE);
          }
        }),
        catchError(e => {
          captureException(e);
          return of(getRoomFailure(action.roomId, ErrorType.AW_SNAP));
        })
      )
    )
  );
}

export const addRoomRequestEpic: Epic<Action, AddRoomAction | RoomFormAction | IChangeRoute, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, IAddRoomRequest>(actionType.ADD_ROOM_REQUEST),
    mergeMap(action => 
      from(chat.addRoom(action.room)).pipe(
        mergeMap(room => of(addRoomSuccess(room), initRoomForm({}), changeRoute('/rooms'))),
        catchError(e => {
          if (/user not set|permission_denied/i.test(e.message)) {
            return of(addRoomFailure(ErrorType.NOT_AUTHORISED));
          } else {
            captureException(e);
            return of(addRoomFailure(ErrorType.AW_SNAP));
          }
        })
      )
    )
  );
}

export const updateRoomRequestEpic: Epic<Action, UpdateRoomAction | RoomFormAction | IChangeRoute, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, IUpdateRoomRequest>(actionType.UPDATE_ROOM_REQUEST),
    mergeMap(action =>
      from(chat.updateRoom(action.roomId, action.room)).pipe(
        mergeMap(room => of(updateRoomSuccess(room.id, room), initRoomForm({}), changeRoute('/rooms'))),
        catchError(e => {
          if (/user not set|permission_denied/i.test(e.message)) {
            return of(updateRoomFailure(action.roomId, ErrorType.NOT_AUTHORISED));
          } else {
            captureException(e);
            return of(updateRoomFailure(action.roomId, ErrorType.AW_SNAP));
          }
        }),
      )
    )
  );
}

export const deleteRoomRequestEpic: Epic<Action, ICloseDeleteRoomModal | IDeleteRoomFailure, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, IDeleteRoomRequest>(actionType.DELETE_ROOM_REQUEST),
    mergeMap(action =>
      from(chat.deleteRoom(action.roomId)).pipe(
        map(() => closeDeleteRoomModal()),
        catchError(e => {
          let error: ErrorType;

          if (/permission_denied/i.test(e.message)) {
            error = ErrorType.NOT_AUTHORISED;
          } else if (/not empty/i.test(e.message)) {
            error = ErrorType.FAILED_TO_DELETE_ROOM_NOT_EMPTY;
          } else {
            error = ErrorType.AW_SNAP;
            captureException(e);
          }

          return of(deleteRoomFailure(action.roomId, error));
        })
      )
    )
  );
}