import React from 'react';
import { IUser } from '../chat/Chat';
import { AvatarNames } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import ErrorMessage from './ErrorMessage';

export interface IProps {
  error?: ErrorType;
  isSubmitting: boolean;
  userProperties: Partial<IUser>;
  onInit: () => void;
  onInputChange: (userProperties: Partial<IUser>) => void;
  onSubmit: (userProperties: Partial<IUser>) => void;
}

class UserForm extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    props.onInit();
  }

  public render() {
    const { error, isSubmitting, userProperties } = this.props;

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return (
      <form noValidate={true} onSubmit={this.submit}>
        <div className="form-group">
          <label htmlFor="user-form-field-name">Name</label>
          <input
            id="user-form-field-name"
            className="form-control"
            type="text"
            required={true}
            name="name"
            value={userProperties.name}
            onChange={this.handleInputChange}
          />
          <div className="invalid-feedback">
            Please provide your name.
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="user-form-field-avatar-name">Avatar</label>
          <select
            id="user-form-field-avatar-name"
            className="custom-select"
            required={true}
            name="avatarName"
            value={userProperties.avatarName}
            onChange={this.handleInputChange}
          >
            <option value="">Select avatar</option>
            {Object.keys(AvatarNames).map(key => <option key={key} value={AvatarNames[key]}>{key}</option>)}
          </select>
          <div className="invalid-feedback">
            Please select your avatar.
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="user-form-field-avatar-url">Custom avatar URL</label>
          <input
            id="user-form-field-avatar-url"
            className="form-control"
            type="url"
            name="avatarUrl"
            value={userProperties.avatarUrl}
            onChange={this.handleInputChange}
          />
          <div className="invalid-feedback">
            Please provide a valid url.
          </div>
        </div>
        <button className="btn btn-primary mr-2" type="submit" disabled={isSubmitting}>Save</button>
      </form>
    );
  }

  public submit(e: React.FormEvent<HTMLFormElement>) {
    const form = e.currentTarget;
    const valid = form.checkValidity();

    form.classList.add('was-validated');

    e.preventDefault();
    e.stopPropagation();

    if (valid) {
      this.props.onSubmit(this.props.userProperties);
    }
  }

  public handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const target = e.target;
    const name = target.name;
    let value: string | boolean = '';

    if (target instanceof HTMLInputElement) {
      value = target.type === 'checkbox' ? target.checked : target.value;
    } else {
      value = target.value;
    }

    this.props.onInputChange({ [name]: value });
  }
}

export default UserForm;