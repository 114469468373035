import { IRoom } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { IRoomsState } from '../reducers/rooms';

export interface ISyncRoomsRequest {
  type: actionType.SYNC_ROOMS_REQUEST;
}

export interface ISyncRoomsSuccess {
  type: actionType.SYNC_ROOMS_SUCCESS;
  rooms: IRoomsState;
}

export interface ISyncRoomsFailure {
  type: actionType.SYNC_ROOMS_FAILURE;
  error: ErrorType;
}

export interface IStopSyncingRoomsRequest {
  type: actionType.STOP_SYNCING_ROOMS_REQUEST;
}

export interface IGetRoomRequest {
  type: actionType.GET_ROOM_REQUEST;
  roomId: string;
}

export interface IGetRoomSuccess {
  type: actionType.GET_ROOM_SUCCESS;
  room: IRoom;
  roomId: string;
}

export interface IGetRoomFailure {
  type: actionType.GET_ROOM_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IAddRoomRequest {
  type: actionType.ADD_ROOM_REQUEST;
  room: Partial<IRoom>;
}

export interface IAddRoomSuccess {
  type: actionType.ADD_ROOM_SUCCESS;
  room: IRoom;
}

export interface IAddRoomFailure {
  type: actionType.ADD_ROOM_FAILURE;
  error: ErrorType;
}

export interface IUpdateRoomRequest {
  type: actionType.UPDATE_ROOM_REQUEST;
  roomId: string;
  room: Partial<IRoom>;
}

export interface IUpdateRoomSuccess {
  type: actionType.UPDATE_ROOM_SUCCESS;
  roomId: string;
  room: IRoom;
}

export interface IUpdateRoomFailure {
  type: actionType.UPDATE_ROOM_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IDeleteRoomRequest {
  type: actionType.DELETE_ROOM_REQUEST;
  roomId: string;
}

export interface IDeleteRoomSuccess {
  type: actionType.DELETE_ROOM_SUCCESS;
  roomId: string;
}

export interface IDeleteRoomFailure {
  type: actionType.DELETE_ROOM_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IInitRoomForm {
  type: actionType.INIT_ROOM_FORM;
  roomProperties: Partial<IRoom>;
}

export interface IChangeRoomForm {
  type: actionType.CHANGE_ROOM_FORM;
  roomProperties: Partial<IRoom>;
}

export interface IEnterRoomRequest {
  type: actionType.ENTER_ROOM_REQUEST;
  roomId: string;
}

export interface IEnterRoomSuccess {
  type: actionType.ENTER_ROOM_SUCCESS;
  roomId: string;
}

export interface IEnterRoomFailure {
  type: actionType.ENTER_ROOM_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IExitRoomRequest {
  type: actionType.EXIT_ROOM_REQUEST;
  roomId: string;
}

export interface IExitRoomSuccess {
  type: actionType.EXIT_ROOM_SUCCESS;
  roomId: string;
}

export interface IExitRoomFailure {
  type: actionType.EXIT_ROOM_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IOpenDeleteRoomModal {
  type: actionType.OPEN_DELETE_ROOM_MODAL;
  roomId: string;
}

export interface ICloseDeleteRoomModal {
  type: actionType.CLOSE_DELETE_ROOM_MODAL;
}

export interface IUpdateChatMessageDraft {
  type: actionType.UPDATE_CHAT_MESSAGE_DRAFT;
  chatMessageDraft: string;
  roomId: string;
}

export interface ISendChatMessageRequest {
  type: actionType.SEND_CHAT_MESSAGE_REQUEST;
  messageId: string;
  roomId: string;
}

export interface ISendChatMessageSuccess {
  type: actionType.SEND_CHAT_MESSAGE_SUCCESS;
  messageId: string;
  roomId: string;
}

export type SyncRoomsAction = ISyncRoomsRequest | ISyncRoomsSuccess | ISyncRoomsFailure | IStopSyncingRoomsRequest;
export type GetRoomAction = IGetRoomRequest | IGetRoomSuccess | IGetRoomFailure;
export type AddRoomAction = IAddRoomRequest | IAddRoomSuccess | IAddRoomFailure;
export type UpdateRoomAction = IUpdateRoomRequest | IUpdateRoomSuccess | IUpdateRoomFailure;
export type DeleteRoomAction = IDeleteRoomRequest | IDeleteRoomSuccess | IDeleteRoomFailure;
export type EnterRoomAction = IEnterRoomRequest | IEnterRoomSuccess | IEnterRoomFailure;
export type ExitRoomAction = IExitRoomRequest | IExitRoomSuccess | IExitRoomFailure;
export type RoomFormAction = IInitRoomForm | IChangeRoomForm;
export type DeleteRoomModalAction = IOpenDeleteRoomModal | ICloseDeleteRoomModal;

export type RoomsAction =
  SyncRoomsAction | GetRoomAction | AddRoomAction | UpdateRoomAction | DeleteRoomAction |
  EnterRoomAction | ExitRoomAction | RoomFormAction | DeleteRoomModalAction |
  IUpdateChatMessageDraft | ISendChatMessageRequest | ISendChatMessageSuccess;

export function syncRoomsRequest(): ISyncRoomsRequest {
  return { type: actionType.SYNC_ROOMS_REQUEST };
}

export function syncRoomsSuccess(rooms: IRoomsState): ISyncRoomsSuccess {
  return {
    rooms,
    type: actionType.SYNC_ROOMS_SUCCESS
  };
}

export function syncRoomsFailure(error: ErrorType): ISyncRoomsFailure {
  return {
    error,
    type: actionType.SYNC_ROOMS_FAILURE
  };
}

export function stopSyncingRoomsRequest(): IStopSyncingRoomsRequest {
  return { type: actionType.STOP_SYNCING_ROOMS_REQUEST };
}

export function getRoomRequest(roomId: string): IGetRoomRequest {
  return {
    roomId,
    type: actionType.GET_ROOM_REQUEST
  };
}

export function getRoomSuccess(roomId: string, room: IRoom): IGetRoomSuccess {
  return {
    room,
    roomId,
    type: actionType.GET_ROOM_SUCCESS
  };
}

export function getRoomFailure(roomId: string, error: ErrorType): IGetRoomFailure {
  return {
    error,
    roomId,
    type: actionType.GET_ROOM_FAILURE
  };
}

export function addRoomRequest(room: Partial<IRoom>): IAddRoomRequest {
  return {
    room,
    type: actionType.ADD_ROOM_REQUEST
  };
}

export function addRoomSuccess(room: IRoom): IAddRoomSuccess {
  return {
    room,
    type: actionType.ADD_ROOM_SUCCESS
  };
}

export function addRoomFailure(error: ErrorType): IAddRoomFailure {
  return {
    error,
    type: actionType.ADD_ROOM_FAILURE
  };
}

export function updateRoomRequest(roomId: string, room: Partial<IRoom>): IUpdateRoomRequest {
  return {
    room,
    roomId,
    type: actionType.UPDATE_ROOM_REQUEST
  };
}

export function updateRoomSuccess(roomId: string, room: IRoom): IUpdateRoomSuccess {
  return {
    room,
    roomId,
    type: actionType.UPDATE_ROOM_SUCCESS
  };
}

export function updateRoomFailure(roomId: string, error: ErrorType): IUpdateRoomFailure {
  return {
    error,
    roomId,
    type: actionType.UPDATE_ROOM_FAILURE
  };
}

export function deleteRoomRequest(roomId: string): IDeleteRoomRequest {
  return {
    roomId,
    type: actionType.DELETE_ROOM_REQUEST
  };
}

export function deleteRoomSuccess(roomId: string): IDeleteRoomSuccess {
  return {
    roomId,
    type: actionType.DELETE_ROOM_SUCCESS
  };
}

export function deleteRoomFailure(roomId: string, error: ErrorType): IDeleteRoomFailure {
  return {
    error,
    roomId,
    type: actionType.DELETE_ROOM_FAILURE
  };
}

export function initRoomForm(roomProperties: Partial<IRoom>): IInitRoomForm {
  return {
    roomProperties,
    type: actionType.INIT_ROOM_FORM
  };
}

export function changeRoomForm(roomProperties: Partial<IRoom>): IChangeRoomForm {
  return {
    roomProperties,
    type: actionType.CHANGE_ROOM_FORM
  };
}

export function enterRoomRequest(roomId: string): IEnterRoomRequest {
  return {
    roomId,
    type: actionType.ENTER_ROOM_REQUEST
  };
}

export function enterRoomSuccess(roomId: string): IEnterRoomSuccess {
  return {
    roomId,
    type: actionType.ENTER_ROOM_SUCCESS
  };
}

export function enterRoomFailure(roomId: string, error: ErrorType): IEnterRoomFailure {
  return {
    error,
    roomId,
    type: actionType.ENTER_ROOM_FAILURE
  };
}

export function exitRoomRequest(roomId: string): IExitRoomRequest {
  return {
    roomId,
    type: actionType.EXIT_ROOM_REQUEST
  };
}

export function exitRoomSuccess(roomId: string): IExitRoomSuccess {
  return {
    roomId,
    type: actionType.EXIT_ROOM_SUCCESS
  };
}

export function exitRoomFailure(roomId: string, error: ErrorType): IExitRoomFailure {
  return {
    error,
    roomId,
    type: actionType.EXIT_ROOM_FAILURE
  };
}

export function openDeleteRoomModal(roomId: string): IOpenDeleteRoomModal {
  return {
    roomId,
    type: actionType.OPEN_DELETE_ROOM_MODAL
  };
}

export function closeDeleteRoomModal(): ICloseDeleteRoomModal {
  return {
    type: actionType.CLOSE_DELETE_ROOM_MODAL
  };
}

export function updateChatMessageDraft(roomId: string, chatMessageDraft: string): IUpdateChatMessageDraft {
  return {
    chatMessageDraft,
    roomId,
    type: actionType.UPDATE_CHAT_MESSAGE_DRAFT
  }
}

export function sendChatMessageRequest(roomId: string, messageId:string): ISendChatMessageRequest {
  return {
    messageId,
    roomId,
    type: actionType.SEND_CHAT_MESSAGE_REQUEST
  };
}

export function sendChatMessageSuccess(roomId: string, messageId:string): ISendChatMessageSuccess {
  return {
    messageId,
    roomId,
    type: actionType.SEND_CHAT_MESSAGE_SUCCESS
  };
}