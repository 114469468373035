import { combineEpics } from 'redux-observable';
import * as authEpics from './auth';
import * as roomEpics from './room';
import * as routeEpics from './route';
import * as userEpics from './user';

export default combineEpics(
  ...values(authEpics),
  ...values(roomEpics),
  ...values(routeEpics),
  ...values(userEpics)
);

function values(object: object) {
  return Object.keys(object).map(key => object[key]);
}