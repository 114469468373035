import React from 'react';
import { IUser } from '../chat/Chat';
import { Avatars, Theme } from '../constants/avatar';
import './ChatMessage.scss';
import UserAvatar from './UserAvatar';

export interface IProps {
  alignRight: boolean;
  content: string;
  fromUser?: IUser;
  isSending: boolean;
  theme: Theme;
}

function ChatMessage({ alignRight, content, fromUser, isSending, theme }: IProps) {
  const alignmentClass = alignRight ? 'chat-message--right' : 'chat-message--left';
  const themeClass = theme === Theme.LIGHT ? 'chat-message--light' : 'chat-message--dark';
  let avatarName;
  let avatarUrl;
  let contentStyle: { background?: string; borderColor?: string; } = {};
  let innerArrowStyle: { borderColor?: string; } = {};
  let outerArrowStyle: { borderColor?: string; } = {};

  if (fromUser && fromUser.avatarName) {
    ({ avatarName, avatarUrl } = fromUser);

    const avatar = Avatars.get(fromUser.avatarName);

    if (avatar) {
      if (theme === Theme.LIGHT) {
        contentStyle = { borderColor: avatar.color };
        outerArrowStyle = { borderColor: `transparent ${avatar.color}` };
      } else {
        contentStyle = { background: avatar.color };
        innerArrowStyle = { borderColor: `transparent ${avatar.color}` };
      }
    }
  }

  return (
    <div className={`media chat-message ${alignmentClass} ${themeClass} ${isSending ? 'chat-message--sending' : ''}`}>
      <UserAvatar theme={theme} avatarName={avatarName} avatarUrl={avatarUrl} />
      <div className="media-body">
        <div className="chat-message-content" style={contentStyle}>
          <div className="chat-message-arrow">
            <div className="chat-message-arrow-outer" style={outerArrowStyle} />
            <div className="chat-message-arrow-inner" style={innerArrowStyle} />
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;