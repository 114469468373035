import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Action, applyMiddleware, createStore, Dispatch } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { changeRoute, getCurrentUserFailure, getCurrentUserRequest, getCurrentUserSuccess, goOffline, goOnline } from './actions';
import Chat from './chat/Chat';
import FirebaseChat from './chat/FirebaseChat';
import App from './components/App';
import { setAppElement as setAppElementForModal } from './components/Modal';
import { history } from './components/Router';
import { AvatarNames } from './constants/avatar';
import { ErrorType } from './constants/error';
import { ChatContext } from './contexts/ChatContext';
import epic from './epics';
import reducer, { IStoreState } from './reducers';
import * as serviceWorker from './serviceWorker';
import { monitorConnectedState } from './util';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
} else {
  throw new Error('Missing Sentry DSN. Set REACT_APP_SENTRY_DSN environment variable.');
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
});

firebase.auth().useDeviceLanguage();

const chat = new FirebaseChat().connect(firebase.database().ref('chat'));

const epicMiddleware = createEpicMiddleware<Action, Action, IStoreState, { auth: firebase.auth.Auth, chat: Chat, history: History }>({
  dependencies: { auth: firebase.auth(), chat, history }
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore<IStoreState, Action, {}, {}>(
  reducer,
  composeWithDevTools({})(
    applyMiddleware(epicMiddleware),
    sentryReduxEnhancer
  )
);

epicMiddleware.run(epic);

monitorConnectedState(chat).subscribe(connected => {
  if (connected) {
    store.dispatch(goOnline());
  } else {
    store.dispatch(goOffline());
  }
});
monitorAuthState(firebase.auth(), store.dispatch);
setAppElementForModal('#root');

ReactDOM.render(
  <React.StrictMode>
    <ChatContext.Provider value={chat}>
      <Provider store={store}>
        <App />
      </Provider>
    </ChatContext.Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
serviceWorker.register();

function monitorAuthState(auth: firebase.auth.Auth, dispatch: Dispatch) {
  auth.onAuthStateChanged(async firebaseUser => {
    if (firebaseUser) {
      let user = null;

      dispatch(getCurrentUserRequest());

      try {
        user = await chat.getUser(firebaseUser.uid);
        const isNewUser = !user;

        if (!user) {
          user = {
            avatarName: getRandomAvatarName(),
            avatarUrl: firebaseUser.photoURL || '',
            id: firebaseUser.uid,
            name: firebaseUser.displayName || ''
          };
        }

        await chat.setUser(user);

        dispatch(getCurrentUserSuccess(chat.user));

        if (isNewUser) {
          dispatch(changeRoute('/settings'));
        }
      } catch (e) {
        dispatch(getCurrentUserFailure(ErrorType.AW_SNAP));
      }
    } else {
      dispatch(getCurrentUserSuccess(null));
    }
  });
}

function getRandomAvatarName() {
  const keys = Object.keys(AvatarNames);
  return AvatarNames[keys[ Math.floor(keys.length * Math.random()) ]];
}