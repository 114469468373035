import React from 'react';
import { Link } from 'react-router-dom';
import { IRoom, IUser } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import { IconPlus } from '../constants/icon';
import ErrorMessage from './ErrorMessage';
import './Modal.scss';
import RoomCard from './RoomCard';
import './RoomCardList.scss';
import RoomCardPlaceholder from './RoomCardPlaceholder';

export interface IProps {
  currentUser?: IUser | null;
  isLoadingRooms: boolean;
  roomsError?: ErrorType;
  rooms: IRoom[];
  onOpenDeleteRoomModal: (roomId: string) => void;
  onSyncRooms: () => void;
  onStopSyncingRooms: () => void;
}

class RoomCardList extends React.PureComponent<IProps> {

  public componentDidMount() {
    this.props.onSyncRooms();
  }

  public componentWillUnmount() {
    this.props.onStopSyncingRooms();
  }

  public render() {
    const { currentUser, isLoadingRooms, roomsError, onOpenDeleteRoomModal } = this.props;

    if (roomsError) {
      return (
        <div className="room-cards">
          <ErrorMessage error={roomsError} doAction={this.props.onSyncRooms} />
        </div>
      );
    }

    if (isLoadingRooms) {
      const placeholders = [];
      for (let i = 0; i < 12; i++) {
        placeholders.push(
          <div className="col" key={i}>
            <RoomCardPlaceholder />
          </div>
        );
      }
      return <div className="room-cards row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">{placeholders}</div>;
    }

    const rooms = this.props.rooms.map(room => (
      <div className="col" key={room.id}>
        <RoomCard
          {...room}
          currentUser={currentUser}
          onDeleteRoomRequest={onOpenDeleteRoomModal}
        />
      </div>
    ));

    if (rooms.length) {
      return (
        <div className="room-cards row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {rooms}
          <div className="col">
            <Link to="/rooms/new" className="add-room-link card text-center d-block">
              <IconPlus className="svg-inline icon-plus" /> Add room
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="room-cards">
          <p className="text-center"><strong>No chat rooms</strong></p>
          <p className="text-center text-muted">
            If you'd like to start talking, <Link to="/rooms/new">add a room</Link>.
          </p>
        </div>
      );
    }
  }
}

export default RoomCardList;
