import { connect } from 'react-redux';
import { ReactNode } from 'react';
import { Dispatch } from 'redux';
import {
  enterRoomFailure,
  enterRoomRequest,
  enterRoomSuccess,
  exitRoomFailure,
  exitRoomRequest,
  exitRoomSuccess,
  IOpenRoomUsersModal,
  openRoomUsersModal,
  RoomsAction,
  sendChatMessageRequest,
  sendChatMessageSuccess,
  updateChatMessageDraft
} from '../actions';
import Chat, { MessageType } from '../chat/Chat';
import Room, { IProps as IRoomProps } from '../components/Room';
import { Theme } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import { IStoreState } from '../reducers';
import { defaultRoomUiState } from '../reducers/ui';

export type IStateProps = Pick<IRoomProps, 'error' | 'chatMessageDraft' | 'inRoom' | 'isEntering' | 'isExiting' | 'isLoadingUserData' | 'room' | 'theme' | 'user'>;
export type IDispatchProps = Pick<IRoomProps, 'onEnterRoom' | 'onExitRoom' | 'onOpenRoomUsersModal' | 'onSendChatMessage' | 'onUpdateChatMessageDraft'>;

export interface IOwnProps {
  chat: Chat;
  roomId: string;
  children: ReactNode;
};

function mapStateToProps(state: IStoreState, { roomId }: IOwnProps): IStateProps {
  const { currentUser, currentUserUi } = state;
  const room = state.rooms[roomId];
  const roomUi = state.roomsUi[roomId] || defaultRoomUiState;

  return {
    chatMessageDraft: roomUi.chatMessageDraft,
    error: roomUi.error,
    inRoom: roomUi.inRoom,
    isEntering: roomUi.isEntering,
    isExiting: roomUi.isExiting,
    isLoadingUserData: currentUserUi.isLoadingUserData,
    room,
    theme: Theme.DARK,
    user: currentUser
  };
}

function mapDisaptchToProps(dispatch: Dispatch<RoomsAction | IOpenRoomUsersModal>, { chat, roomId }: IOwnProps): IDispatchProps {
  return {
    onEnterRoom: () => {
      dispatch(enterRoomRequest(roomId));

      chat.enterRoom(roomId)
        .then(() => dispatch(enterRoomSuccess(roomId)))
        .catch(() => dispatch(enterRoomFailure(roomId, ErrorType.AW_SNAP)));
    },
    onExitRoom: () => {
      dispatch(exitRoomRequest(roomId));

      chat.exitRoom(roomId)
        .then(() => dispatch(exitRoomSuccess(roomId)))
        .catch(() => dispatch(exitRoomFailure(roomId, ErrorType.AW_SNAP)));
    },
    onOpenRoomUsersModal: () => dispatch(openRoomUsersModal(roomId)),
    onSendChatMessage: content => {
      chat.sendMessage(
        roomId,
        content,
        MessageType.USER,
        messageId => dispatch(sendChatMessageRequest(roomId, messageId))
      )
        .then(message => dispatch(sendChatMessageSuccess(roomId, message.id)));
    },
    onUpdateChatMessageDraft: content => dispatch(updateChatMessageDraft(roomId, content))
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(mapStateToProps, mapDisaptchToProps)(Room);
