import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { changeUserForm, initUserForm, updateCurrentUserRequest, UsersAction } from '../actions';
import { IUser } from '../chat/Chat';
import UserForm, { IProps as IUserFormProps } from '../components/UserForm';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<IUserFormProps, 'error' | 'isSubmitting' | 'userProperties'> & { currentUser: IUser | null };
export interface IDispatchProps { dispatch: Dispatch<UsersAction> };

function mapStateToProps({ currentUser, userForm }: IStoreState): IStateProps {
  return {
    currentUser,
    ...userForm
  };
}

function mapDispatchToProps(dispatch: Dispatch<UsersAction>): IDispatchProps {
  return { dispatch };
}

function mergeProps(stateProps: IStateProps, { dispatch }: IDispatchProps): IUserFormProps {
  const { currentUser, ...restStateProps } = stateProps;

  return {
    ...restStateProps,
    onInit: () => {
      if (currentUser) {
        dispatch(initUserForm(currentUser));
      }
    },
    onInputChange: userProperties => dispatch(changeUserForm(userProperties)),
    onSubmit: userProperties => dispatch(updateCurrentUserRequest(userProperties))
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserForm);