import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { stopSyncingUsersOnlineRequest, syncUsersOnlineRequest, UsersOnlineAction } from '../actions';
import { IUser } from '../chat/Chat';
import UserList, { IProps as IUserListProps } from '../components/UserList';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<IUserListProps, 'currentUser' | 'error' | 'isLoading' | 'users'>;
export type IDispatchProps = Pick<IUserListProps, 'onSyncUsers' | 'onStopSyncingUsers'>;

function mapStateToProps({ currentUser, usersOnline, userOnlineListUi }: IStoreState): IStateProps {
  const { error, isLoading } = userOnlineListUi;
  const users = Object.keys(usersOnline).map(id => usersOnline[id] as IUser);

  return { currentUser, error, isLoading, users };
}

function mapDispatchToProps(dispatch: Dispatch<UsersOnlineAction>): IDispatchProps {
  return {
    onStopSyncingUsers: () => dispatch(stopSyncingUsersOnlineRequest()),
    onSyncUsers: () => dispatch(syncUsersOnlineRequest())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);