import { IUser } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { IUsersOnlineState } from '../reducers/users';

export interface IGetCurrentUserRequest {
  type: actionType.GET_CURRENT_USER_REQUEST;
}

export interface IGetCurrentUserSuccess {
  type: actionType.GET_CURRENT_USER_SUCCESS;
  user: IUser | null;
}

export interface IGetCurrentUserFailure {
  type: actionType.GET_CURRENT_USER_FAILURE;
  error: ErrorType;
}

export interface IUpdateCurrentUserRequest {
  type: actionType.UPDATE_CURRENT_USER_REQUEST;
  userProperties: Partial<IUser>;
}

export interface IUpdateCurrentUserSuccess {
  type: actionType.UPDATE_CURRENT_USER_SUCCESS;
  user: IUser;
}

export interface IUpdateCurrentUserFailure {
  type: actionType.UPDATE_CURRENT_USER_FAILURE;
  error: ErrorType;
}

export interface IInitUserForm {
  type: actionType.INIT_USER_FORM;
  userProperties: Partial<IUser>;
}

export interface IChangeUserForm {
  type: actionType.CHANGE_USER_FORM;
  userProperties: Partial<IUser>;
}

export interface ISyncUsersOnlineRequest {
  type: actionType.SYNC_USERS_ONLINE_REQUEST;
}

export interface ISyncUsersOnlineSuccess {
  type: actionType.SYNC_USERS_ONLINE_SUCCESS;
  users: IUsersOnlineState;
}

export interface ISyncUsersOnlineFailure {
  type: actionType.SYNC_USERS_ONLINE_FAILURE;
  error: ErrorType;
}

export interface IStopSyncingUsersOnlineRequest {
  type: actionType.STOP_SYNCING_USERS_ONLINE_REQUEST;
}

export interface IAddUserOnlineSuccess {
  type: actionType.ADD_USER_ONLINE_SUCCESS;
  user: IUser;
}

export interface IDeleteUserOnlineSuccess {
  type: actionType.DELETE_USER_ONLINE_SUCCESS;
  userId: string;
}

export interface ISyncRoomUsersRequest {
  type: actionType.SYNC_ROOM_USERS_REQUEST;
  roomId: string;
}

export interface ISyncRoomUsersSuccess {
  type: actionType.SYNC_ROOM_USERS_SUCCESS;
  roomId: string;
  users: IUsersOnlineState;
}

export interface ISyncRoomUsersFailure {
  type: actionType.SYNC_ROOM_USERS_FAILURE;
  error: ErrorType;
  roomId: string;
}

export interface IStopSyncingRoomUsersRequest {
  type: actionType.STOP_SYNCING_ROOM_USERS_REQUEST;
  roomId: string;
}

export interface IAddRoomUserSuccess {
  type: actionType.ADD_ROOM_USER_SUCCESS;
  roomId: string;
  user: IUser;
}

export interface IDeleteRoomUserSuccess {
  type: actionType.DELETE_ROOM_USER_SUCCESS;
  roomId: string;
  userId: string;
}

export interface IOpenRoomUsersModal {
  type: actionType.OPEN_ROOM_USERS_MODAL;
  roomId: string;
}

export interface ICloseRoomUsersModal {
  type: actionType.CLOSE_ROOM_USERS_MODAL;
  roomId: string;
}

export type GetCurrentUserAction = IGetCurrentUserRequest | IGetCurrentUserSuccess | IGetCurrentUserFailure;
export type UpdateCurrentUserAction = IUpdateCurrentUserRequest | IUpdateCurrentUserSuccess | IUpdateCurrentUserFailure;
export type UserFormAction = IInitUserForm | IChangeUserForm;
export type UsersOnlineAction = ISyncUsersOnlineRequest | ISyncUsersOnlineSuccess | ISyncUsersOnlineFailure | IStopSyncingUsersOnlineRequest | IAddUserOnlineSuccess | IDeleteUserOnlineSuccess;
export type RoomUsersAction = ISyncRoomUsersRequest | ISyncRoomUsersSuccess | ISyncRoomUsersFailure | IStopSyncingRoomUsersRequest | IAddRoomUserSuccess | IDeleteRoomUserSuccess;
export type RoomUsersModalAction = IOpenRoomUsersModal | ICloseRoomUsersModal;

export type UsersAction = GetCurrentUserAction | UpdateCurrentUserAction | UserFormAction | UsersOnlineAction | RoomUsersAction | RoomUsersModalAction;

export function getCurrentUserRequest(): IGetCurrentUserRequest {
  return { type: actionType.GET_CURRENT_USER_REQUEST };
}

export function getCurrentUserSuccess(user: IUser | null): IGetCurrentUserSuccess {
  return {
    type: actionType.GET_CURRENT_USER_SUCCESS,
    user
  };
}

export function getCurrentUserFailure(error: ErrorType): IGetCurrentUserFailure {
  return {
    error,
    type: actionType.GET_CURRENT_USER_FAILURE
  };
}

export function updateCurrentUserRequest(userProperties: Partial<IUser>): IUpdateCurrentUserRequest {
  return {
    type: actionType.UPDATE_CURRENT_USER_REQUEST,
    userProperties
  };
}

export function updateCurrentUserSuccess(user: IUser): IUpdateCurrentUserSuccess {
  return {
    type: actionType.UPDATE_CURRENT_USER_SUCCESS,
    user
  };
}

export function updateCurrentUserFailure(error: ErrorType): IUpdateCurrentUserFailure {
  return {
    error,
    type: actionType.UPDATE_CURRENT_USER_FAILURE
  };
}

export function initUserForm(userProperties: Partial<IUser>): IInitUserForm {
  return {
    type: actionType.INIT_USER_FORM,
    userProperties
  };
}

export function changeUserForm(userProperties: Partial<IUser>): IChangeUserForm {
  return {
    type: actionType.CHANGE_USER_FORM,
    userProperties
  };
}

export function syncUsersOnlineRequest(): ISyncUsersOnlineRequest {
  return { type: actionType.SYNC_USERS_ONLINE_REQUEST };
}

export function syncUsersOnlineSuccess(users: IUsersOnlineState): ISyncUsersOnlineSuccess {
  return {
    type: actionType.SYNC_USERS_ONLINE_SUCCESS,
    users
  };
}

export function syncUsersOnlineFailure(error: ErrorType): ISyncUsersOnlineFailure {
  return {
    error,
    type: actionType.SYNC_USERS_ONLINE_FAILURE
  };
}

export function stopSyncingUsersOnlineRequest(): IStopSyncingUsersOnlineRequest {
  return { type: actionType.STOP_SYNCING_USERS_ONLINE_REQUEST };
}

export function addUserOnlineSuccess(user: IUser): IAddUserOnlineSuccess {
  return {
    type: actionType.ADD_USER_ONLINE_SUCCESS,
    user
  };
}

export function deleteUserOnlineSuccess(userId: string): IDeleteUserOnlineSuccess {
  return {
    type: actionType.DELETE_USER_ONLINE_SUCCESS,
    userId
  };
}

export function syncRoomUsersRequest(roomId: string): ISyncRoomUsersRequest {
  return {
    roomId,
    type: actionType.SYNC_ROOM_USERS_REQUEST
  };
}

export function syncRoomUsersSuccess(roomId: string, users: IUsersOnlineState): ISyncRoomUsersSuccess {
  return {
    roomId,
    type: actionType.SYNC_ROOM_USERS_SUCCESS,
    users
  };
}

export function syncRoomUsersFailure(roomId: string, error: ErrorType): ISyncRoomUsersFailure {
  return {
    error,
    roomId,
    type: actionType.SYNC_ROOM_USERS_FAILURE
  };
}

export function stopSyncingRoomUsersRequest(roomId: string): IStopSyncingRoomUsersRequest {
  return {
    roomId,
    type: actionType.STOP_SYNCING_ROOM_USERS_REQUEST
  };
}

export function addRoomUserSuccess(roomId: string, user: IUser): IAddRoomUserSuccess {
  return {
    roomId,
    type: actionType.ADD_ROOM_USER_SUCCESS,
    user
  };
}

export function deleteRoomUserSuccess(roomId: string, userId: string): IDeleteRoomUserSuccess {
  return {
    roomId,
    type: actionType.DELETE_ROOM_USER_SUCCESS,
    userId
  };
}

export function openRoomUsersModal(roomId: string): IOpenRoomUsersModal {
  return {
    roomId,
    type: actionType.OPEN_ROOM_USERS_MODAL
  };
}

export function closeRoomUsersModal(roomId: string): ICloseRoomUsersModal {
  return {
    roomId,
    type: actionType.CLOSE_ROOM_USERS_MODAL
  };
}