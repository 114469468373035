import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { closeRoomUsersModal, RoomUsersAction, RoomUsersModalAction, stopSyncingRoomUsersRequest, syncRoomUsersRequest } from '../actions';
import { IUser } from '../chat/Chat';
import UserListModal, { IProps as IUserListModalProps } from '../components/UserListModal';
import { IStoreState } from '../reducers';
import { defaultRoomUsersModalState, IUsersModalUiState } from '../reducers/ui';

export type IStateProps = Pick<IUserListModalProps, 'currentUser' | 'error' | 'isLoading' | 'isOpen' | 'users'>;
export type IDispatchProps = Pick<IUserListModalProps, 'onClose' | 'onSyncUsers' | 'onStopSyncingUsers'>;
export interface IOwnProps { roomId: string }

function mapStateToProps({ currentUser, roomUsers, roomUsersModalsUi }: IStoreState, { roomId }: IOwnProps): IStateProps {
  const usersModalUi: IUsersModalUiState = roomUsersModalsUi[roomId] || defaultRoomUsersModalState;
  const users = roomUsers[roomId] || {};

  return {
    currentUser,
    error: usersModalUi.error,
    isLoading: usersModalUi.isLoading,
    isOpen: usersModalUi.isOpen,
    users: Object.keys(users).map(id => users[id] as IUser)
  };
}

function mapDispatchToProps(dispatch: Dispatch<RoomUsersAction | RoomUsersModalAction>, { roomId }: IOwnProps): IDispatchProps {
  return {
    onClose: () => dispatch(closeRoomUsersModal(roomId)),
    onStopSyncingUsers: () => dispatch(stopSyncingRoomUsersRequest(roomId)),
    onSyncUsers: () => dispatch(syncRoomUsersRequest(roomId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListModal);