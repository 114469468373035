import { captureException } from '@sentry/react';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { from, fromEvent, merge, of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  addRoomUserSuccess,
  addUserOnlineSuccess,
  changeRoute,
  deleteRoomUserSuccess,
  deleteUserOnlineSuccess,
  IAddRoomUserSuccess,
  IAddUserOnlineSuccess,
  IChangeRoute,
  IDeleteRoomUserSuccess,
  IDeleteUserOnlineSuccess,
  initUserForm,
  IStopSyncingRoomUsersRequest,
  ISyncRoomUsersFailure,
  ISyncRoomUsersRequest,
  ISyncRoomUsersSuccess,
  ISyncUsersOnlineFailure,
  ISyncUsersOnlineRequest,
  ISyncUsersOnlineSuccess,
  IUpdateCurrentUserRequest,
  syncRoomUsersFailure,
  syncRoomUsersSuccess,
  syncUsersOnlineFailure,
  syncUsersOnlineSuccess,
  UpdateCurrentUserAction,
  updateCurrentUserFailure,
  updateCurrentUserSuccess,
  UserFormAction
} from '../actions';
import Chat, { EventType, IUser } from '../chat/Chat';
import { STOP_SYNCING_ROOM_USERS_REQUEST, STOP_SYNCING_USERS_ONLINE_REQUEST, SYNC_ROOM_USERS_REQUEST, SYNC_ROOM_USERS_SUCCESS, SYNC_USERS_ONLINE_REQUEST, SYNC_USERS_ONLINE_SUCCESS, UPDATE_CURRENT_USER_REQUEST } from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { IStoreState } from '../reducers';

export const updateUserRequestEpic: Epic<Action, UpdateCurrentUserAction | UserFormAction | IChangeRoute, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action ,IUpdateCurrentUserRequest>(UPDATE_CURRENT_USER_REQUEST),
    mergeMap(action =>
      from(chat.updateUser(action.userProperties)).pipe(
        mergeMap(() => {
          if (chat.user) {
            return of(updateCurrentUserSuccess(chat.user), initUserForm({}), changeRoute('/'));
          } else {
            return of(updateCurrentUserFailure(ErrorType.AW_SNAP));
          }
        }),
        catchError(e => of(updateCurrentUserFailure(ErrorType.AW_SNAP)))
      )
    )
  );
};

export const syncUsersOnlineRequestEpic: Epic<Action, ISyncUsersOnlineSuccess | ISyncUsersOnlineFailure, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<ISyncUsersOnlineRequest>(SYNC_USERS_ONLINE_REQUEST),
    switchMap(() =>
      from(chat.syncUsersOnline()).pipe(
        map(users => syncUsersOnlineSuccess(users)),
        catchError(e => {
          captureException(e);
          return of(syncUsersOnlineFailure(ErrorType.AW_SNAP));
        })
      )
    )
  );
};

export const syncUsersOnlineSuccessEpic: Epic<Action, IAddUserOnlineSuccess | IDeleteUserOnlineSuccess, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType(SYNC_USERS_ONLINE_SUCCESS),
    switchMap(action =>
      merge<IAddUserOnlineSuccess | IDeleteUserOnlineSuccess>(
        fromEvent<IUser>(chat, EventType.USER_ONLINE_ADD).pipe(
          filter(user => !state$.value.usersOnline[user.id]),
          map(user => addUserOnlineSuccess(user))
        ),
        fromEvent<IUser>(chat, EventType.USER_ONLINE_DELETE).pipe(map(user => deleteUserOnlineSuccess(user.id)))
      ).pipe(
        takeUntil(action$.pipe(
          ofType(STOP_SYNCING_USERS_ONLINE_REQUEST)
        ))
      )
    )
  );
};

export const stopSyncingUsersOnlineRequestEpic: Epic<Action, Action, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType(STOP_SYNCING_USERS_ONLINE_REQUEST),
    tap(() => chat.stopSyncingUsersOnline()),
    ignoreElements()
  );
};

export const syncRoomUsersRequestEpic: Epic<Action, ISyncRoomUsersSuccess | ISyncRoomUsersFailure, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, ISyncRoomUsersRequest>(SYNC_ROOM_USERS_REQUEST),
    switchMap(action =>
      from(chat.syncRoomUsers(action.roomId)).pipe(
        map(users => syncRoomUsersSuccess(action.roomId, users)),
        catchError(e => {
          captureException(e);
          return of(syncRoomUsersFailure(action.roomId, ErrorType.AW_SNAP));
        })
      )
    )
  );
};

export const syncRoomUsersSuccessEpic: Epic<Action, IAddRoomUserSuccess | IDeleteRoomUserSuccess, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, ISyncRoomUsersSuccess>(SYNC_ROOM_USERS_SUCCESS),
    switchMap(action =>
      merge<IAddRoomUserSuccess | IDeleteRoomUserSuccess>(
        fromEvent<IUser>(chat, EventType.ROOM_USER_ADD).pipe(
          filter(user => !(state$.value.roomUsers[action.roomId] || {})[user.id]),
          map(user => addRoomUserSuccess(action.roomId, user))
        ),
        fromEvent<IUser>(chat, EventType.ROOM_USER_DELETE).pipe(map(user => deleteRoomUserSuccess(action.roomId, user.id)))
      ).pipe(
        takeUntil(action$.pipe(
          ofType(STOP_SYNCING_ROOM_USERS_REQUEST)
        ))
      )
    )
  );
};

export const stopSyncingRoomUsersRequestEpic: Epic<Action, Action, IStoreState, { chat: Chat }> = (action$, state$, { chat }) => {
  return action$.pipe(
    ofType<Action, IStopSyncingRoomUsersRequest>(STOP_SYNCING_ROOM_USERS_REQUEST),
    tap(action => chat.stopSyncingRoomUsers(action.roomId)),
    ignoreElements()
  );
};