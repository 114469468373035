import React from 'react';
import { Avatars, Theme } from '../constants/avatar';
import './UserAvatar.scss';

export interface IProps {
  theme: Theme;
  avatarName?: string;
  avatarUrl?: string;
}

function UserAvatar(props: IProps) {
  const { theme, avatarName, avatarUrl } = props;
  let avatar;

  if (avatarName) {
    avatar = Avatars.get(avatarName);
  }

  if (!avatar) {
    return <div className="user-avatar" />;
  }

  if (avatarUrl) {
    return (
      <div
        className="user-avatar user-avatar-border"
        style={{
          backgroundImage: `url(${avatarUrl})`,
          borderColor: theme === Theme.LIGHT ? avatar.color : 'white'
        }}
      />
    );
  } else {
    const DefaultAvatarComponent = theme === Theme.LIGHT ? avatar.svgLight : avatar.svgDark;
    return <div className="user-avatar"><DefaultAvatarComponent /></div>;
  }
}

export default UserAvatar;
