import React from 'react';
import { Link } from 'react-router-dom';
import { IRoom, IUser } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import './RoomCard.scss';
import RoomCardImage from './RoomCardImage';
import UserAvatar from './UserAvatar';

export interface IProps {
  id?: string;
  name: string;
  description?: string;
  imageUrl?: string;
  lastMessage?: IRoom['lastMessage'];
  createdByUser?: IRoom['createdByUser'];
  currentUser?: IUser | null;
  onDeleteRoomRequest?: (roomId: string) => void;
}

class RoomCard extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.deleteRoom = this.deleteRoom.bind(this);
  }

  public render() {
    const { name, imageUrl = '' } = this.props;

    return (
      <div className="card room-card">
        <RoomCardImage src={imageUrl} alt="Room Image" className="card-img-top" />
        <div className="card-body">
          <h6 className="card-title text-truncate" title={name}>{name}</h6>
          {this.renderDescription()}
          {this.renderLastMessage()}
          {this.renderActions()}
        </div>
      </div>
    );
  }

  private renderDescription() {
    const { description } = this.props;

    if (!description) return <p className="card-text d-none d-sm-block">&nbsp;</p>;
    return <p className="card-text text-muted text-truncate" title={description}>{description}</p>;
  }

  private renderLastMessage() {
    const { lastMessage } = this.props;

    if (!lastMessage) return <div className="card-text invisible d-none d-sm-block"><UserAvatar theme={Theme.LIGHT} /></div>;

    const { content, fromUser } = lastMessage;

    return (
      <div className="card-text text-muted text-truncate" title={content}>
        {fromUser && <UserAvatar theme={Theme.LIGHT} avatarName={fromUser.avatarName} avatarUrl={fromUser.avatarUrl} />}
        {content}
      </div>
    );
  }

  private renderActions() {
    const { id, createdByUser, currentUser } = this.props;

    if (!id) return null;

    let isAdmin = false;

    if (currentUser && createdByUser) isAdmin = currentUser.id === createdByUser.id;

    return (
      <React.Fragment>
        <Link to={`/rooms/${id}`} className="btn btn-link card-link p-0">Enter</Link>
        {isAdmin && <Link to={`/rooms/${id}/edit`} className="btn btn-link card-link p-0">Change</Link>}
        {isAdmin && <button className="btn btn-link card-link p-0 text-danger" onClick={this.deleteRoom}>Delete</button>}
      </React.Fragment>
    );
  }

  private deleteRoom() {
    const { id, onDeleteRoomRequest } = this.props;

    if (id && onDeleteRoomRequest) onDeleteRoomRequest(id);
  }
}

export default RoomCard;