import React from 'react';
import Chat from '../chat/Chat';
import ChatMessageList from '../containers/ChatMessageList';
import GetRoom from '../containers/GetRoom';
import Room from '../containers/Room';
import RoomUsersModal from '../containers/RoomUsersModal';
import { ChatContext } from '../contexts/ChatContext';

export default ({ roomId }: { roomId: string }) => (
  <div className="room-page">
    <GetRoom roomId={roomId}>
      <ChatContext.Consumer>
        {(chat: Chat) => (
          <Room chat={chat} roomId={roomId}>
            <ChatMessageList chat={chat} roomId={roomId} />
            <RoomUsersModal roomId={roomId} />
          </Room>
        )}
      </ChatContext.Consumer>
    </GetRoom>
  </div>
);
