import React from 'react';
import { IconHeartBroken } from '../constants/icon';
import './RoomCardImage.scss';

export interface IProps {
  alt?: string;
  className?: string;
  src: string;
}

export interface IState {
  failedToLoadImage: boolean;
  isLoadingImage: boolean;
}

export default class RoomCardImage extends React.PureComponent<IProps, IState> {
  public state: IState = {
    failedToLoadImage: false,
    isLoadingImage: true
  }

  constructor(props: IProps) {
    super(props);

    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }

  public render() {
    const { alt, className, src } = this.props;
    const { failedToLoadImage, isLoadingImage } = this.state;

    return (
      <React.Fragment>
        <img
          alt={alt}
          className={`${className} ${isLoadingImage || failedToLoadImage ? 'd-none' : 'd-block'} room-card-image`}
          src={src}
          onLoad={this.onLoad}
          onError={this.onError}
        />
        {isLoadingImage && <div className={`${className} room-card-image-loading placeholder-background`} />}
        {failedToLoadImage && <div className={`${className} room-card-image-broken text-center`}><IconHeartBroken className="svg-inline" /> This image can't be displayed.</div>}
      </React.Fragment>
    );
  }

  private onLoad() {
    this.setState({ isLoadingImage: false });
  }

  private onError() {
    this.setState({ isLoadingImage: false, failedToLoadImage: true });
  }
}