import { GO_OFFLINE, GO_ONLINE } from '../constants/actionType';

export interface IGoOnline { type: GO_ONLINE; }

export interface IGoOffline { type: GO_OFFLINE };

export type OnlineAction = IGoOnline | IGoOffline;

export function goOnline(): IGoOnline {
  return { type: GO_ONLINE };
}

export function goOffline(): IGoOffline {
  return { type: GO_OFFLINE };
}