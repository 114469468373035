import { OnlineAction } from "../actions";
import { GO_OFFLINE, GO_ONLINE } from "../constants/actionType";

export function online(onlineState: boolean = true, action: OnlineAction) {
  if (action.type === GO_ONLINE) {
    return true;
  } else if (action.type === GO_OFFLINE) {
    return false;
  } else {
    return onlineState;
  }
}