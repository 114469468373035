import React from 'react';
import { IRoom, IUser } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import ErrorMessage from './ErrorMessage';
import './Room.scss';
import RoomHeader from './RoomHeader';
import ScrollToElementButton from './ScrollToElementButton';

export interface IProps {
  chatMessageDraft?: string;
  error?: ErrorType;
  inRoom: boolean;
  isEntering: boolean;
  isExiting: boolean;
  isLoadingUserData: boolean;
  theme: Theme;
  room?: IRoom;
  user?: IUser | null;
  onEnterRoom: () => void;
  onExitRoom: () => void;
  onOpenRoomUsersModal: () => void;
  onSendChatMessage: (content: string) => void;
  onUpdateChatMessageDraft: (content: string) => void;
}

export interface IState { showScrollToTopButton: boolean }

class Room extends React.PureComponent<IProps, IState> {

  public state: IState = { showScrollToTopButton: false };

  constructor(props: IProps) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  public componentDidMount() {
    this.props.onEnterRoom();

    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    this.props.onExitRoom();

    window.removeEventListener('scroll', this.handleScroll);
  }

  public render() {
    const {
      chatMessageDraft,
      children,
      error,
      inRoom,
      isEntering,
      isExiting,
      isLoadingUserData,
      onOpenRoomUsersModal,
      onSendChatMessage,
      onUpdateChatMessageDraft,
      room,
      theme,
      user
    } = this.props;
    const themeClass = theme === Theme.DARK ? 'bg-dark text-white' : '';
    let content: React.ReactNode;

    if (error) {
      return this.renderError(error);
    }

    if (isEntering) {
      content = <p className="text-center">Entering room...</p>;
    } else if (isExiting) {
      content = <p className="text-center">Exiting room...</p>;
    } else if (inRoom) {
      content = children;
    } else {
      content = null;
    }

    return (
      <div className={`room-container ${themeClass}`}>
        <RoomHeader
          chatMessageDraft={chatMessageDraft}
          isLoadingUserData={isLoadingUserData}
          isSignedIn={!!user}
          room={room}
          onOpenRoomUsersModal={onOpenRoomUsersModal}
          onSendChatMessage={onSendChatMessage}
          onUpdateChatMessageDraft={onUpdateChatMessageDraft}
        />
        <div className="container-xl my-3">
          {content}
          {this.state.showScrollToTopButton && <ScrollToElementButton elementSelector=".room-container" theme={theme} />}
        </div>
      </div>
    );
  }

  private renderError(error: ErrorType) {
    return (
      <div className="container-xl my-3">
        <ErrorMessage error={error} />
      </div>
    );
  }

  private handleScroll(e: Event) {
    if (window.scrollY > window.innerHeight / 2) {
      this.setState({ showScrollToTopButton: true });
    } else {
      this.setState({ showScrollToTopButton: false });
    }
  }
}

export default Room;