import React from 'react';
import AppHeader from '../containers/AppHeader';
import DeleteRoomModal from '../containers/DeleteRoomModal';
import RoomCardList from '../containers/RoomCardList';

export default () => (
  <div className="room-list-page">
    <AppHeader />
    <div className="container-xl mt-3">
      <RoomCardList />
      <DeleteRoomModal />
    </div>
  </div>
);
