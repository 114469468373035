import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getRoomRequest, IGetRoomRequest } from '../actions';
import { IRoom } from '../chat/Chat';
import ErrorMessage from '../components/ErrorMessage';
import { ErrorType } from '../constants/error';
import { IStoreState } from '../reducers';
import { defaultRoomUiState } from '../reducers/ui';

export interface IGetRoomComponentProps {
  error?: ErrorType;
  isLoading: boolean;
  room?: IRoom;
  onGetRoom: () => void;
}

class GetRoomComponent extends React.PureComponent<IGetRoomComponentProps> {

  public componentDidMount() {
    const { room, onGetRoom } = this.props;

    if (!room) {
      onGetRoom();
    }
  }

  public render() {
    const { children, error, isLoading } = this.props;

    if (error) {
      return <ErrorMessage error={error} />;
    } else if (isLoading) {
      return <p className="text-center">Loading room...</p>;
    } else {
      return children;
    }
  }
}

export type IStateProps = Pick<IGetRoomComponentProps, 'error' | 'isLoading' | 'room'>;
export type IDispatchProps = Pick<IGetRoomComponentProps, 'onGetRoom'>;
export interface IOwnProps { roomId: string, children: ReactNode };

function mapStateToProps({ rooms, roomsUi }: IStoreState, { roomId }: IOwnProps): IStateProps {
  const room = rooms[roomId];
  const { error, isLoading } = roomsUi[roomId] || defaultRoomUiState;

  return { error, isLoading, room };
}

function mapDispatchToProps(dispatch: Dispatch<IGetRoomRequest>, { roomId }: IOwnProps): IDispatchProps {
  return { onGetRoom: () => dispatch(getRoomRequest(roomId)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetRoomComponent);
