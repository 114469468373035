import { FunctionComponent } from 'react';
import { ReactComponent as BakyuraLight } from '../media/avatars/bakyura.svg';
import { ReactComponent as BakyuraDark } from '../media/avatars/bakyura-dark.svg';
import { ReactComponent as GakiLight } from '../media/avatars/gaki.svg';
import { ReactComponent as GakiDark } from '../media/avatars/gaki-dark.svg';
import { ReactComponent as JunsuiLight } from '../media/avatars/junsui.svg';
import { ReactComponent as JunsuiDark } from '../media/avatars/junsui-dark.svg';
import { ReactComponent as KanraLight } from '../media/avatars/kanra.svg';
import { ReactComponent as KanraDark } from '../media/avatars/kanra-dark.svg';
import { ReactComponent as KuromuLight } from '../media/avatars/kuromu.svg';
import { ReactComponent as KuromuDark } from '../media/avatars/kuromu-dark.svg';
import { ReactComponent as KyoLight } from '../media/avatars/kyo.svg';
import { ReactComponent as KyoDark } from '../media/avatars/kyo-dark.svg';
import { ReactComponent as RocchiLight } from '../media/avatars/rocchi.svg';
import { ReactComponent as RocchiDark } from '../media/avatars/rocchi-dark.svg';
import { ReactComponent as SakiLight } from '../media/avatars/saki.svg';
import { ReactComponent as SakiDark } from '../media/avatars/saki-dark.svg';
import { ReactComponent as SanLight } from '../media/avatars/san.svg';
import { ReactComponent as SanDark } from '../media/avatars/san-dark.svg';
import { ReactComponent as SettonLight } from '../media/avatars/setton.svg';
import { ReactComponent as SettonDark } from '../media/avatars/setton-dark.svg';
import { ReactComponent as SharoLight } from '../media/avatars/sharo.svg';
import { ReactComponent as SharoDark } from '../media/avatars/sharo-dark.svg';
import { ReactComponent as TanakaLight } from '../media/avatars/tanaka.svg';
import { ReactComponent as TanakaDark } from '../media/avatars/tanaka-dark.svg';
import { ReactComponent as YaLight } from '../media/avatars/ya.svg';
import { ReactComponent as YaDark } from '../media/avatars/ya-dark.svg';
import { ReactComponent as ZaikaLight } from '../media/avatars/zaika.svg';
import { ReactComponent as ZaikaDark } from '../media/avatars/zaika-dark.svg';

export enum Theme { DARK = 'dark', LIGHT = 'light' }

export interface IAvatar {
  color: string;
  svgLight: FunctionComponent;
  svgDark: FunctionComponent;
}

export enum AvatarNames {
  Bakyura = 'bakyura',
  Gaki = 'gaki',
  Junsui = 'junsui',
  Kanra = 'kanra',
  Kuromu = 'kuromu',
  Kyo = 'kyo',
  Rocchi = 'rocchi',
  Saki = 'saki',
  San = 'san',
  Setton = 'setton',
  Sharo = 'sharo',
  Tanaka = 'tanaka',
  Ya = 'ya',
  Zaika = 'zaika'
}

export const Avatars: Map<string, IAvatar> = new Map();

Avatars.set(AvatarNames.Bakyura, { color: '#74E238', svgLight: BakyuraLight, svgDark: BakyuraDark });
Avatars.set(AvatarNames.Gaki,    { color: '#41BE2E', svgLight: GakiLight, svgDark: GakiDark });
Avatars.set(AvatarNames.Junsui,  { color: '#B2813F', svgLight: JunsuiLight, svgDark: JunsuiDark });
Avatars.set(AvatarNames.Kanra,   { color: '#FFA726', svgLight: KanraLight, svgDark: KanraDark });
Avatars.set(AvatarNames.Kuromu,  { color: '#C800F3', svgLight: KuromuLight, svgDark: KuromuDark });
Avatars.set(AvatarNames.Kyo,     { color: '#14CDE8', svgLight: KyoLight, svgDark: KyoDark });
Avatars.set(AvatarNames.Rocchi,  { color: '#E6DE29', svgLight: RocchiLight, svgDark: RocchiDark });
Avatars.set(AvatarNames.Saki,    { color: '#F1C410', svgLight: SakiLight, svgDark: SakiDark });
Avatars.set(AvatarNames.San,     { color: '#3633A7', svgLight: SanLight, svgDark: SanDark });
Avatars.set(AvatarNames.Setton,  { color: '#898F98', svgLight: SettonLight, svgDark: SettonDark });
Avatars.set(AvatarNames.Sharo,   { color: '#6BC3E5', svgLight: SharoLight, svgDark: SharoDark });
Avatars.set(AvatarNames.Tanaka,  { color: '#0879C4', svgLight: TanakaLight, svgDark: TanakaDark });
Avatars.set(AvatarNames.Ya,      { color: '#797b81', svgLight: YaLight, svgDark: YaDark });
Avatars.set(AvatarNames.Zaika,   { color: '#D32F2F', svgLight: ZaikaLight, svgDark: ZaikaDark });
