import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';

export interface IGoogleAuthRequest {
  type: actionType.GOOGLE_AUTH_REQUEST;
}

export interface IGetRedirectResultRequest {
  type: actionType.GET_REDIRECT_RESULT_REQUEST;
}

export interface ISendEmailLinkRequest {
  type: actionType.SEND_EMAIL_LINK_REQUEST;
  email: string;
}

export interface ISendEmailLinkSuccess {
  type: actionType.SEND_EMAIL_LINK_SUCCESS;
  email: string;
}

export interface IEmailLinkAuthRequest {
  type: actionType.EMAIL_LINK_AUTH_REQUEST;
  email: string;
}

export interface IUnauthRequest {
  type: actionType.UNAUTH_REQUEST;
}

export interface IAuthSuccess {
  type: actionType.AUTH_SUCCESS;
}

export interface IAuthFailure {
  type: actionType.AUTH_FAILURE;
  error: ErrorType;
}

export type AuthAction = IGoogleAuthRequest | IGetRedirectResultRequest |
  ISendEmailLinkRequest | ISendEmailLinkSuccess | IEmailLinkAuthRequest |
  IUnauthRequest |
  IAuthSuccess | IAuthFailure;

export function googleAuthRequest(): IGoogleAuthRequest {
  return { type: actionType.GOOGLE_AUTH_REQUEST };
}

export function getRedirectResultRequest(): IGetRedirectResultRequest {
  return { type: actionType.GET_REDIRECT_RESULT_REQUEST };
}

export function sendEmailLinkRequest(email: string): ISendEmailLinkRequest {
  return {
    email,
    type: actionType.SEND_EMAIL_LINK_REQUEST
  };
}

export function sendEmailLinkSuccess(email: string): ISendEmailLinkSuccess {
  return {
    email,
    type: actionType.SEND_EMAIL_LINK_SUCCESS
  };
}

export function emailLinkAuthRequest(email: string): IEmailLinkAuthRequest {
  return {
    email,
    type: actionType.EMAIL_LINK_AUTH_REQUEST
  };
}

export function unauthRequest(): IUnauthRequest {
  return { type: actionType.UNAUTH_REQUEST };
}

export function authSuccess(): IAuthSuccess {
  return { type: actionType.AUTH_SUCCESS };
}

export function authFailure(error: ErrorType): IAuthFailure {
  return {
    error,
    type: actionType.AUTH_FAILURE
  };
}