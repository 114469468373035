import React from 'react';
import { IconEnvelope } from '../constants/icon';

export interface IProps {
  disabled: boolean;
  onSendEmailLink: (email: string) => void;
}

export default class SignInWithEmailLink extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  public render() {
    const { disabled } = this.props;
    return (
      <form onSubmit={this.submit} noValidate={true}>
        <div className="form-group">
          <input
            type="email"
            required={true}
            disabled={disabled}
            name="email"
            className="form-control"
            placeholder="Enter email"
          />
          <div className="invalid-feedback">Please give a valid email address so we can send the sign in link to it.</div>
        </div>
        <button type="submit" className="btn btn-block btn-success" disabled={disabled}>
          <IconEnvelope className="svg-inline" /> Sign in with Email
        </button>
      </form>
    );
}

  private submit(e: React.FormEvent<HTMLFormElement>) {
    const form = e.currentTarget;
    const valid = form.checkValidity();

    form.classList.add('was-validated');

    e.preventDefault();
    e.stopPropagation();

    if (valid) {
      this.props.onSendEmailLink(form.email.value);
    }
  }
}
