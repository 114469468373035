import React from 'react';
import './RoomCardPlaceholder.scss';
import RoomCardImage from './RoomCardImage';

export interface IProps {
  imageUrl?: string;
}

function RoomCardPlaceholder({ imageUrl }: IProps) {
  return (
    <div className="card room-card-placeholder">
      {imageUrl ? <RoomCardImage src={imageUrl} alt="Room Image" className="card-img-top" /> : <div className="placeholder-background card-img-top" />}
      <div className="card-body">
        <div className="room-card-body-placeholder placeholder-background">
          <div className="title-right" />
          <div className="title-bottom" />
          <div className="description-spacer-1" />
          <div className="description-spacer-2" />
          <div className="description-right" />
          <div className="description-bottom" />
          <div className="link-right" />
        </div>
      </div>
    </div>
  );
}

export default RoomCardPlaceholder;
