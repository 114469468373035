import React from 'react';

export interface IProps {
  disabled: boolean;
  onSignIn: (email: string) => void;
}

export default class ConfirmEmailToSignIn extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  public render() {
    const { disabled } = this.props;
    return (
      <form onSubmit={this.submit} noValidate={true}>
        <p>Looks like you are on a different device from where you requested the sign in link. Please enter the email to which the sign in link was sent.</p>
        <div className="form-group">
          <input
            type="email"
            required={true}
            disabled={disabled}
            name="email"
            className="form-control"
            placeholder="Enter email"
          />
          <div className="invalid-feedback">Please enter the email to which the sign in link was sent.</div>
        </div>
        <button type="submit" className="btn btn-block btn-success" disabled={disabled}>
          Continue to sign in
        </button>
      </form>
    );
}

  private submit(e: React.FormEvent<HTMLFormElement>) {
    const form = e.currentTarget;
    const valid = form.checkValidity();

    form.classList.add('was-validated');

    e.preventDefault();
    e.stopPropagation();

    if (valid) {
      this.props.onSignIn(form.email.value);
    }
  }
}