import { CHANGE_ROUTE } from '../constants/actionType';

export interface IChangeRoute {
  type: CHANGE_ROUTE;
  path: string;
}

export function changeRoute(path: string): IChangeRoute {
  return {
    path,
    type: CHANGE_ROUTE
  };
}