import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { closeDeleteRoomModal, deleteRoomRequest, RoomsAction } from '../actions';
import DeleteRoomModal, { IProps as IDeleteRoomModalProps } from '../components/DeleteRoomModal';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<IDeleteRoomModalProps, 'error' | 'isLoading' | 'isOpen' | 'roomData'>;
export type IDispatchProps = Pick<IDeleteRoomModalProps, 'onClose' | 'onDeleteRoom'>;

function mapStateToProps({ deleteRoomModalUi, rooms }: IStoreState): IStateProps {
  const { error, isLoading, isOpen, roomId } = deleteRoomModalUi;
  let roomData;

  if (isOpen && roomId) {
    roomData = rooms[roomId];
  }

  return { error, isLoading, isOpen, roomData };
}

function mapDispatchToProps(dispatch: Dispatch<RoomsAction>): IDispatchProps {
  return {
    onClose: () => dispatch(closeDeleteRoomModal()),
    onDeleteRoom: (roomId: string) => dispatch(deleteRoomRequest(roomId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRoomModal);