import React from 'react';
import { Link } from 'react-router-dom';
import { IRoom } from '../chat/Chat';
import ChatMessageForm from './ChatMessageForm';
import { IconUsers, IconArrowLeft } from '../constants/icon';
import './RoomHeader.scss';

export interface IProps {
  chatMessageDraft?: string;
  isLoadingUserData: boolean;
  isSignedIn: boolean;
  room?: IRoom;
  onOpenRoomUsersModal: () => void;
  onSendChatMessage: (content: string) => void;
  onUpdateChatMessageDraft: (content: string) => void;
}

class RoomHeader extends React.PureComponent<IProps> {

  public render() {
    const { isLoadingUserData, isSignedIn } = this.props;

    return (
      <React.Fragment>
        {this.rednerNavbar()}
        {isLoadingUserData || isSignedIn ? this.renderChatMessageForm() : this.renderNotAuthorised()}
      </React.Fragment>
    );
  }

  private rednerNavbar() {
    const { room, onOpenRoomUsersModal } = this.props;
    const roomName = room ? room.name : '';

    return (
      <nav className="room-header navbar navbar-light bg-light">
        <div className="container-xl">
          <Link to="/rooms" className="nav-link text-black-50 pl-0">
            <IconArrowLeft className="svg-inline" />
          </Link>
          <span className="room-header-name navbar-text text-truncate text-dark">{roomName}</span>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <button className="btn btn-link nav-link border-0 pr-0" onClick={onOpenRoomUsersModal}>
                <IconUsers className="svg-inline icon-users" />
              </button>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  private renderChatMessageForm() {
    const { chatMessageDraft = '', isLoadingUserData, onSendChatMessage, onUpdateChatMessageDraft } = this.props;

    return (
      <div className="bg-light position-relative pb-2">
        <div className="container-xl">
          <ChatMessageForm
            content={chatMessageDraft}
            disabled={isLoadingUserData}
            onContentChange={onUpdateChatMessageDraft}
            onSubmit={onSendChatMessage}
          />
        </div>
      </div>
    );
  }

  private renderNotAuthorised() {
    return (
      <div className="bg-light">
        <div className="container-xl">
          <p className="m-0 text-dark text-center pb-3">
            <Link to="/sign-in">Sign in</Link> to start talking.
          </p>
        </div>
      </div>
    );
  }
}

export default RoomHeader;
