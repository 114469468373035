import React from 'react';
import { IUser } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import Modal from './Modal';
import UserListModalContent from './UserListModalContent';

export interface IProps {
  currentUser?: IUser | null;
  error?: ErrorType;
  isLoading: boolean;
  isOpen: boolean;
  users: IUser[];
  onClose: () => void;
  onSyncUsers: () => void;
  onStopSyncingUsers: () => void;
}

export default function UserListModal({isOpen, onClose, ...otherProps }: IProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <UserListModalContent onClose={onClose} {...otherProps} />
    </Modal>
  );
}