import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { IMessage, IUser, MessageType } from '../chat/Chat';
import { AvatarNames, Theme } from '../constants/avatar';
import { IconFacebook, IconTwitter, IconWeibo } from '../constants/icon';
import AppHeader from '../containers/AppHeader';
import ChatMessageList from './ChatMessageList';
import './HomePage.scss';
import UserAvatar from './UserAvatar';
import RoomCardPlaceholder from './RoomCardPlaceholder';

const users: IUser[] = [
  { id: 'user-1', name: 'Tanaka', avatarName: AvatarNames.Tanaka },
  { id: 'user-2', name: 'Kanra', avatarName: AvatarNames.Kanra },
  { id: 'user-3', name: 'San', avatarName: AvatarNames.San}
];

const messages: IMessage[] = [
  { id: 'message-6', type: MessageType.USER, fromUser: users[0], content: 'Hello' },
  { id: 'message-5', type: MessageType.SYSTEM_ENTER_ROOM, content: users[0].name },
  { id: 'message-4', type: MessageType.USER, fromUser: users[1], content: 'こんにちは' },
  { id: 'message-3', type: MessageType.USER, fromUser: users[2], content: '你好。电脑修的好，好人当到老' },
  { id: 'message-2', type: MessageType.USER, fromUser: users[1], content: 'Hola' }
];

export default () => (
  <div className="home-page">
    <AppHeader />
    <section className="hero bg-dark text-white py-lg-5">
      <div className="container-xl pt-5 pb-md-5">
        <div className="row">
          <div className="col-md text-center text-md-left mt-md-5 ml-md-5">
            <h1 className="text-warning">Chat Rooms from Durarara</h1>
            <p className="lead">The anime fan community that you share your interests with.</p>
            {CallToAction()}
          </div>
          <div className="col-md">
            <div className="room-card-display-group mx-auto">
              <div className="room-card-display">
                <div className="room-card-display-inner">
                  <RoomCardPlaceholder imageUrl="/images/room-card-image-3.jpg" />
                </div>
              </div>
              <div className="room-card-display">
                <div className="room-card-display-inner">
                  <RoomCardPlaceholder imageUrl="/images/room-card-image-2.jpg" />
                </div>
              </div>
              <div className="room-card-display">
                <div className="room-card-display-inner">
                  <RoomCardPlaceholder imageUrl="/images/room-card-image-1.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="avatars py-lg-3">
      <div className="container-xl py-5">
        <div className="row flex-lg-row-reverse">
          <div className="col-lg mt-lg-4">
            <h2>Avatars</h2>
            <p className="lead">Pick your favorite character's color and avatar from Durarara. You can also use your own avatar image.</p>
          </div>
          <div className="col-lg mr-lg-5">
            <div className="avatar-list-display">
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Bakyura} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Gaki} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Junsui} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Kanra} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Kuromu} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Kyo} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Rocchi} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Saki} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.San} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Setton} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Sharo} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Tanaka} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Ya} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Zaika} />
              <UserAvatar theme={Theme.LIGHT} avatarName={AvatarNames.Tanaka} avatarUrl="/images/avatar-shin-chan.jpg" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-dark text-white py-lg-3">
      <div className="container-xl py-5">
        <div className="row">
          <div className="col-md mb-4 mt-sm-4 ml-md-5">
            <h2>Chat Room</h2>
            <p className="lead">Bring chat rooms from Durarara to life.</p>
            {CallToAction()}
          </div>
          <div className="col-md">
            <div className="chat-message-list-display">
              <ChatMessageList
                isLoadingMessages={false}
                messages={messages}
                messagesUi={{}}
                theme={Theme.DARK}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className="pt-3">
      <div className="container-xl">
        <p><small><strong>Drrr Chat</strong> © {new Date().getFullYear()}</small></p>
      </div>
    </footer>
  </div>
);

function CallToAction() {
  return (
    <div className="call-to-action">
      <Link to="/rooms" className="btn btn-warning px-4 py-2">Start Chatting</Link>
      <div className="share-links">
        <a onClick={share} data-provider="Facebook" aria-label="Share on Facebook" href="https://www.facebook.com/sharer/sharer.php?u=https://durarara.me">
          <IconFacebook className="svg-inline" />
        </a>
        <a onClick={share} data-provider="Twitter" aria-label="Share on Twitter" href="https://twitter.com/intent/tweet?text=Durarara%20Chat&url=https://durarara.me">
          <IconTwitter className="svg-inline" />
        </a>
        <a onClick={share} data-provider="Weibo" aria-label="Share on Weibo" href="http://service.weibo.com/share/share.php?url=https://durarara.me&amp;appkey=&amp;title=Durarara%20Chat&amp;pic=&amp;ralateUid=&amp;language=zh_cn">
          <IconWeibo className="svg-inline" />
        </a>
      </div>
    </div>
  );
}

function share(event: MouseEvent<HTMLAnchorElement>) {
  event.preventDefault();

  const element = event.currentTarget;

  window.open(element.href, element.dataset.provider, 'width=580,height=300');

  return false;
}
