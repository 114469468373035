import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from 'redux';
import {
  AuthAction,
  emailLinkAuthRequest,
  getRedirectResultRequest,
  googleAuthRequest,
  sendEmailLinkRequest,
  unauthRequest
} from '../actions';
import SignInPage, { IProps as ISignInPageProps } from '../components/SignInPage';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<ISignInPageProps, 'error' | 'emailLinkSent' | 'isLoadingUserCredential' | 'isLoadingUserData' | 'isSignedIn'>;
export type IDispatchProps = Pick<ISignInPageProps, 'onGetRedirectResult' | 'onSendEmailLink' | 'onSignInWithEmailLink' | 'onSignInWithGoogle' | 'onSignOut'>;
export type IOwnProps = RouteComponentProps<{}>;

function mapStateToProps({ currentUser, currentUserUi }: IStoreState): IStateProps {
  const { error, emailLinkSent, isLoadingUserData, isLoadingUserCredential } = currentUserUi;

  return {
    emailLinkSent,
    error,
    isLoadingUserCredential,
    isLoadingUserData,
    isSignedIn: !!currentUser
  };
}

function mapDispatchToProps(dispatch: Dispatch<AuthAction>): IDispatchProps {
  return {
    onGetRedirectResult: () => dispatch(getRedirectResultRequest()),
    onSendEmailLink: email => dispatch(sendEmailLinkRequest(email)),
    onSignInWithEmailLink: email => dispatch(emailLinkAuthRequest(email)),
    onSignInWithGoogle: () => dispatch(googleAuthRequest()),
    onSignOut: () => dispatch(unauthRequest())
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(mapStateToProps, mapDispatchToProps)(SignInPage);