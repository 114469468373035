import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DeleteRoomModalAction, openDeleteRoomModal, stopSyncingRoomsRequest, SyncRoomsAction, syncRoomsRequest } from '../actions';
import { IRoom } from '../chat/Chat';
import RoomCardList, { IProps as IRoomCardListProps } from '../components/RoomCardList';
import { IStoreState } from '../reducers';

export type IStateProps = Pick<IRoomCardListProps, 'currentUser' | 'isLoadingRooms' | 'roomsError' | 'rooms'>;
export type IDispatchProps = Pick<IRoomCardListProps, 'onOpenDeleteRoomModal' | 'onSyncRooms' | 'onStopSyncingRooms'>;

function mapStateToProps({ currentUser, deleteRoomModalUi, roomListUi, rooms }: IStoreState): IStateProps {
  const roomIds = Object.keys(rooms);

  return {
    currentUser,
    isLoadingRooms: roomIds.length === 0 && roomListUi.isLoading,
    rooms: roomIds.map(id => rooms[id] as IRoom).sort(compareRooms),
    roomsError: roomListUi.error
  };
}

function mapDispatchToProps(dispatch: Dispatch<SyncRoomsAction | DeleteRoomModalAction>): IDispatchProps {
  return {
    onOpenDeleteRoomModal: (roomId: string) =>  dispatch(openDeleteRoomModal(roomId)),
    onStopSyncingRooms: () => dispatch(stopSyncingRoomsRequest()),
    onSyncRooms: () => dispatch(syncRoomsRequest())
  };
}

function compareRooms(a: IRoom, b: IRoom) {
  a.updatedAt = a.updatedAt || 0;
  b.updatedAt = b.updatedAt || 0;

  return b.updatedAt - a.updatedAt;
}

export default connect<IStateProps, IDispatchProps, {}, IStoreState>(mapStateToProps, mapDispatchToProps)(RoomCardList);