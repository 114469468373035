import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppHeader from '../containers/AppHeader';
import GetRoom from '../containers/GetRoom';
import RoomForm from '../containers/RoomForm';

export default ({ match }: RouteComponentProps<{ id: string }>) => (
  <React.Fragment>
    <AppHeader />
    <div className="container-xl my-3">
      <GetRoom roomId={match.params.id}>
        <RoomForm roomId={match.params.id} />
      </GetRoom>
    </div>
  </React.Fragment>
);
