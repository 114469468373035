import { UsersAction } from '../actions';
import { IUser } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';

export interface IUserFormState {
  error?: ErrorType;
  isSubmitting: boolean;
  userProperties: Partial<IUser>;
}

export const defaultUserFormState: IUserFormState = {
  isSubmitting: false,
  userProperties: { name: '', avatarName: '', avatarUrl: ''}
};

export function userForm(state: IUserFormState = defaultUserFormState, action: UsersAction): IUserFormState {
  switch (action.type) {
    case actionType.UPDATE_CURRENT_USER_REQUEST:
      return { ...state, isSubmitting: true };
    case actionType.UPDATE_CURRENT_USER_SUCCESS:
      return { ...state, isSubmitting: false };
    case actionType.UPDATE_CURRENT_USER_FAILURE:
      return { ...state, isSubmitting: false, error: action.error };
    case actionType.INIT_USER_FORM:
      return {
        ...defaultUserFormState,
        userProperties: { ...defaultUserFormState.userProperties, ...action.userProperties }
      };
      case actionType.CHANGE_USER_FORM:
      return {
        ...state,
        userProperties: { ...state.userProperties, ...action.userProperties }
      };
    default:
      return state;
  }
}