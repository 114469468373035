import React from 'react';
import { IconGoogle } from '../constants/icon';

export interface IProps {
  disabled: boolean;
  onSignIn: () => void;
}

export default function({ disabled, onSignIn }: IProps) {
  return (
    <button type="button" className="btn btn-block btn-primary" disabled={disabled} onClick={onSignIn}>
      <IconGoogle className="svg-inline" /> Sign in with Google
    </button>
  );
}
