import React from 'react';
import { Theme } from '../constants/avatar';
import { IconArrowUp } from '../constants/icon';
import './ScrollToElementButton.scss';

export interface IProps {
  elementSelector: string;
  theme: Theme;
}

class ScrollToElementButton extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.scrollToElement = this.scrollToElement.bind(this);
  }

  public render() {
    return (
      <button className="scroll-to-element-button btn rounded-circle" onClick={this.scrollToElement}>
        <IconArrowUp className="svg-inline" />
      </button>
    );
  }

  private scrollToElement() {
    const { elementSelector } = this.props;
    let element;

    try {
      element = document.querySelector(elementSelector);
    } catch (e) {
      element = null;
    }

    if (element) {
      element.scrollIntoView();
    }
  }
}

export default ScrollToElementButton;