import { RoomsAction } from '../actions';
import { IRoom } from '../chat/Chat';
import * as actionType from '../constants/actionType';

export interface IRoomsState { [roomId: string]: IRoom | undefined }

export function rooms(state: IRoomsState = {}, action: RoomsAction): IRoomsState {
  switch (action.type) {
    case actionType.SYNC_ROOMS_SUCCESS:
      return action.rooms;
    case actionType.GET_ROOM_SUCCESS:
    case actionType.ADD_ROOM_SUCCESS:
      return { ...state, [action.room.id]: action.room };
    case actionType.UPDATE_ROOM_SUCCESS:
      return { ...state, [action.roomId]: action.room }; 
    case actionType.DELETE_ROOM_SUCCESS:
      state = { ...state };
      delete state[action.roomId];
      return state;
    default:
      return state;
  }
}