import { RoomsAction } from '../actions';
import { IRoom } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';

export interface IRoomFormState {
  error?: ErrorType;
  isSubmitting: boolean;
  roomProperties: Partial<IRoom>;
}

export const defaultRoomFormState: IRoomFormState = {
  isSubmitting: false,
  roomProperties: { name: '', description: '', imageUrl: ''}
};

export function roomForm(state: IRoomFormState = defaultRoomFormState, action: RoomsAction): IRoomFormState {
  switch (action.type) {
    case actionType.ADD_ROOM_REQUEST:
    case actionType.UPDATE_ROOM_REQUEST:
      return { ...state, isSubmitting: true };
    case actionType.ADD_ROOM_SUCCESS:
    case actionType.UPDATE_ROOM_SUCCESS:
      return { ...state, isSubmitting: false };
    case actionType.ADD_ROOM_FAILURE:
    case actionType.UPDATE_ROOM_FAILURE:
      return { ...state, isSubmitting: false, error: action.error };
    case actionType.INIT_ROOM_FORM:
      return {
        ...defaultRoomFormState,
        roomProperties: { ...defaultRoomFormState.roomProperties, ...action.roomProperties }
      };
    case actionType.CHANGE_ROOM_FORM:
      return {
        ...state,
        roomProperties: { ...state.roomProperties, ...action.roomProperties }
      };
    default:
      return state;
  }
}