import { captureException } from '@sentry/react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  addRoomMessageSuccess,
  deleteRoomMessageSuccess,
  MessagesAction,
  syncRoomMessagesFailure,
  syncRoomMessagesRequest,
  syncRoomMessagesSuccess,
  updateRoomMessageSuccess
} from '../actions';
import Chat, { EventType, IMessage } from '../chat/Chat';
import ChatMessageList, { IProps as IChatMessageListProps } from '../components/ChatMessageList';
import { Theme } from '../constants/avatar';
import { ErrorType } from '../constants/error';
import { IStoreState } from '../reducers';
import { IMessageListUiState } from '../reducers/ui';

export interface IOwnProps { chat: Chat; roomId: string }

export type IStateProps = Pick<IChatMessageListProps, 'currentUserId' | 'isLoadingMessages' | 'messagesError' | 'messagesUi' | 'messages' | 'theme'>

export type IDispatchProps = Pick<IChatMessageListProps, 'onSyncMessages' | 'onStopSyncingMessages'>

function mapStateToProps({ currentUser, roomMessageListUi, roomMessages, roomMessagesUi }: IStoreState, { roomId }: IOwnProps) {
  const currentUserId = currentUser ? currentUser.id : undefined;
  const messageListUi: IMessageListUiState = roomMessageListUi[roomId] || { isLoading: false };
  const messages = roomMessages[roomId] || {};
  const messagesUi = roomMessagesUi[roomId] || {};

  return {
    currentUserId,
    isLoadingMessages: messageListUi.isLoading,
    messages: Object.keys(messages).map(id => messages[id] as IMessage).reverse(),
    messagesError: messageListUi.error,
    messagesUi,
    theme: Theme.DARK
  };
}

function mapDispatchToProps(dispatch: Dispatch<MessagesAction>, { chat, roomId }: IOwnProps): IDispatchProps {
  return {
    onStopSyncingMessages: () => {
      chat.stopSyncingRoomMessages(roomId);
      chat.off(EventType.ROOM_MESSAGE_ADD, onRoomMessageAdded);
      chat.off(EventType.ROOM_MESSAGE_UPDATE, onRoomMessageUpdated);
      chat.off(EventType.ROOM_MESSAGE_DELETE, onRoomMessageDeleted);
    },
    onSyncMessages: () => {
      dispatch(syncRoomMessagesRequest(roomId));

      chat.syncRoomMessages(roomId)
        .then(messages => {
          dispatch(syncRoomMessagesSuccess(roomId, messages));

          chat.on(EventType.ROOM_MESSAGE_ADD, onRoomMessageAdded);
          chat.on(EventType.ROOM_MESSAGE_UPDATE, onRoomMessageUpdated);
          chat.on(EventType.ROOM_MESSAGE_DELETE, onRoomMessageDeleted);
        })
        .catch(e => {
          dispatch(syncRoomMessagesFailure(roomId, ErrorType.AW_SNAP));
          captureException(e);
        });
    }
  };

  function onRoomMessageAdded(message: IMessage) {
    dispatch(addRoomMessageSuccess(roomId, message));
  }

  function onRoomMessageUpdated(message: IMessage) {
    dispatch(updateRoomMessageSuccess(roomId, message.id, message));
  }

  function onRoomMessageDeleted(message: IMessage) {
    dispatch(deleteRoomMessageSuccess(roomId, message.id));
  }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(mapStateToProps, mapDispatchToProps)(ChatMessageList);