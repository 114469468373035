import { captureException } from '@sentry/react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';
import {
  authFailure,
  authSuccess,
  IAuthFailure,
  IAuthSuccess,
  IEmailLinkAuthRequest,
  ISendEmailLinkRequest,
  ISendEmailLinkSuccess,
  sendEmailLinkSuccess
} from '../actions';
import Chat from '../chat/Chat';
import { EMAIL_LINK_AUTH_REQUEST, GET_REDIRECT_RESULT_REQUEST, GOOGLE_AUTH_REQUEST, SEND_EMAIL_LINK_REQUEST, UNAUTH_REQUEST } from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { EMAIL_FOR_SIGN_IN } from '../constants/key';
import { IStoreState } from '../reducers';

export const googleAuthRequestEpic: Epic<Action, IAuthFailure, IStoreState, { auth: firebase.auth.Auth }> = (action$, state$, { auth }) => {
  return action$.pipe(
    ofType(GOOGLE_AUTH_REQUEST),
    mergeMap(() =>
      from(auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider())).pipe(
        ignoreElements(),
        catchError(e => {
          captureException(e);
          return of(authFailure(ErrorType.AUTH_ERROR));
        })
      )
    )
  );
};

export const getRedirectResultRequestEpic: Epic<Action, IAuthSuccess | IAuthFailure, IStoreState, { auth: firebase.auth.Auth }> = (action$, state$, { auth }) => {
  return action$.pipe(
    ofType(GET_REDIRECT_RESULT_REQUEST),
    mergeMap(() => 
      from(auth.getRedirectResult()).pipe(
        map(() => authSuccess()),
        catchError(e => {
          captureException(e);
          return of(authFailure(ErrorType.AUTH_ERROR));
        })
      )
    )
  );
};

export const sendEmailLinkRequestEpic: Epic<Action, ISendEmailLinkSuccess | IAuthFailure, IStoreState, { auth: firebase.auth.Auth }> = (action$, state$, { auth }) => {
  return action$.pipe(
    ofType<Action, ISendEmailLinkRequest>(SEND_EMAIL_LINK_REQUEST),
    mergeMap(action =>
      from(auth.sendSignInLinkToEmail(action.email, { url: window.location.href, handleCodeInApp: true })).pipe(
        tap(() => {
          try {
            window.localStorage.setItem(EMAIL_FOR_SIGN_IN, action.email);
          } catch { /** recover */ }
        }),
        map(() => sendEmailLinkSuccess(action.email)),
        catchError(e => {
          captureException(e);
          return of(authFailure(ErrorType.AUTH_ERROR));
        })
      )
    )
  );
};

export const emailLinkAuthRequestEpic: Epic<Action, IAuthSuccess | IAuthFailure, IStoreState, { auth: firebase.auth.Auth }> = (action$, state$, { auth }) => {
  return action$.pipe(
    ofType<Action, IEmailLinkAuthRequest>(EMAIL_LINK_AUTH_REQUEST),
    mergeMap(action => 
      from(auth.signInWithEmailLink(action.email, window.location.href)).pipe(
        tap(() => {
          try {
            window.localStorage.removeItem(EMAIL_FOR_SIGN_IN);
          } catch { /** recover */ }
        }),
        map(() => authSuccess()),
        catchError(e => {
          captureException(e);
          return of(authFailure(ErrorType.AUTH_ERROR));
        })
      )
    )
  );
}

export const unauthRequestEpic: Epic<Action, IAuthSuccess | IAuthFailure, IStoreState, { auth: firebase.auth.Auth, chat: Chat }> = (action$, state$, { auth, chat }) => {
  return action$.pipe(
    ofType(UNAUTH_REQUEST),
    mergeMap(() =>
      from(chat.unsetUser().then(() => auth.signOut())).pipe(
        map(() => authSuccess()),
        catchError(e => {
          captureException(e);
          return of(authFailure(ErrorType.AUTH_ERROR));
        })
      )
    )
  );
};