import { concat, fromEvent, merge, of } from 'rxjs';
import { catchError, map, take, timeout } from 'rxjs/operators';
import Chat, { EventType } from './chat/Chat';

export function monitorConnectedState(chat: Chat) {
  const onlineOrTimeout$ = fromEvent(chat, EventType.ONLINE).pipe(
    map(() => true),
    take(1),
    timeout(10000),
    catchError(() => of(false))
  );

  return concat(onlineOrTimeout$, merge(
    fromEvent(chat, EventType.ONLINE).pipe(map(() => true)),
    fromEvent(chat, EventType.OFFLINE).pipe(map(() => false))
  ));
}