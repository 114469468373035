import { RoomUsersAction, UsersOnlineAction } from '../actions/users';
import { IUser } from '../chat/Chat';
import * as actionType from '../constants/actionType';

export interface IUsersOnlineState { [id: string]: IUser | undefined }

export function usersOnline(state: IUsersOnlineState = {}, action: UsersOnlineAction): IUsersOnlineState {
  switch(action.type) {
    case actionType.SYNC_USERS_ONLINE_SUCCESS:
      return action.users;
    case actionType.ADD_USER_ONLINE_SUCCESS:
      return { ...state, [action.user.id]: action.user };
    case actionType.DELETE_USER_ONLINE_SUCCESS:
      state = { ...state };
      delete state[action.userId];
      return state;
    default:
      return state;
  }
}

export interface IRoomUsersState { [roomId: string]: IUsersOnlineState | undefined }

export function roomUsers(state: IRoomUsersState = {}, action: RoomUsersAction): IRoomUsersState {
  const users = state[action.roomId];

  switch(action.type) {
    case actionType.SYNC_ROOM_USERS_SUCCESS:
      return { ...state, [action.roomId]: action.users };
    case actionType.ADD_ROOM_USER_SUCCESS:
      return { ...state, [action.roomId]: { ...users, [action.user.id]: action.user } };
    case actionType.DELETE_ROOM_USER_SUCCESS:
      const usersCopy = { ...users };
      delete usersCopy[action.userId];
      return { ...state, [action.roomId]: usersCopy };
    default:
      return state;
  }
}