import React from 'react';
import './ChatMessageForm.scss';

export interface IProps {
  content: string;
  disabled: boolean;
  onContentChange: (content: string) => void;
  onSubmit: (content: string) => void;
}

export default class ChatMessageForm extends React.PureComponent<IProps> {

  private formRef: React.RefObject<HTMLFormElement>;

  constructor(props: IProps) {
    super(props);

    this.formRef = React.createRef();
    this.submit = this.submit.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
  }

  public render() {
    const { content, disabled } = this.props;

    return (
      <form noValidate={true} onSubmit={this.submit} ref={this.formRef} className="chat-message-form">
        <div className="form-group mb-2">
          <textarea
            className="form-control"
            disabled={disabled}
            required={true}
            maxLength={140}
            rows={2}
            name="content"
            value={content}
            onChange={this.handleContentChange}
            onKeyDown={this.handleEnterKey}
          />
        </div>
        <button className="btn btn-post d-block mx-auto" disabled={disabled} type="submit">POST!</button>
      </form>
    );
  }

  private handleEnterKey(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      }

      if (e.ctrlKey) {
        const { content, onContentChange } = this.props;
        return onContentChange(content + '\n');
      }

      e.preventDefault();
      (this.formRef.current as HTMLFormElement).dispatchEvent(new Event('submit'));
    }
  }

  private handleContentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;

    this.props.onContentChange(value);
  }

  private submit(e: React.FormEvent<HTMLFormElement>) {
    const form = e.currentTarget;
    const valid = form.checkValidity();

    form.classList.add('was-validated');

    e.preventDefault();
    e.stopPropagation();

    if (valid) {
      this.props.onSubmit(this.props.content);
      form.classList.remove('was-validated');
    }
  }
}
