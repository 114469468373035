import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IUser } from '../chat/Chat';
import { Theme } from '../constants/avatar';
import './AppHeader.scss';
import Collapse from './Collapse';
import UserAvatar from './UserAvatar';

export interface IProps {
  isLoadingUserData: boolean;
  online: boolean;
  user?: IUser | null;
}

export interface IState {
  isMenuOpen: boolean;
}

class AppHeader extends React.PureComponent<IProps, IState> {

  public state: IState = {
    isMenuOpen: false
  };

  constructor(props: IProps) {
    super(props);

    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  public render() {
    const { isLoadingUserData, online, user } = this.props;
    const { isMenuOpen } = this.state;
    let userInfo;
    let brand;

    if (user) {
      userInfo = (
        <NavLink to="/settings" className="d-block" onClick={this.closeMenu}>
          <UserAvatar theme={Theme.DARK} avatarName={user.avatarName} avatarUrl={user.avatarUrl} />
        </NavLink>
      );
    } else if (isLoadingUserData) {
      userInfo = <UserAvatar theme={Theme.DARK} />;
    } else {
      userInfo =
        <Link to="/sign-in" className="nav-link text-white font-weight-bold" onClick={this.closeMenu}>Sign in</Link>;
    }

    if (online) {
      brand = <Link to="/" className="navbar-brand" onClick={this.closeMenu}>Drrr</Link>;
    } else {
      brand = <span className="navbar-brand">Disconnected</span>;
    }

    return (
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
        <div className="container-xl">
          <button
            type="button"
            className="navbar-toggler border-0 px-0"
            aria-controls="app-header-nav"
            aria-expanded={isMenuOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={this.toggleMenu}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {brand}
          <Collapse
            className="order-1 order-sm-0"
            id="app-header-nav"
            isOpen={isMenuOpen}
            navbar={true}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink exact={true} to="/rooms" className="nav-link" onClick={this.closeMenu}>Rooms</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/rooms/new" className="nav-link" onClick={this.closeMenu}>Add room</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/members" className="nav-link" onClick={this.closeMenu}>Members</NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="mailto:odduid+durarara.support@gmail.com">Contact</a>
              </li>
            </ul>
          </Collapse>
          <ul className="navbar-nav">
            <li className="nav-item">{userInfo}</li>
          </ul>
        </div>
      </nav>
    );
  }

  private openMenu() {
    this.setState({ isMenuOpen: true });
  }

  private closeMenu() {
    this.setState({ isMenuOpen: false });
  }

  private toggleMenu() {
    if (this.state.isMenuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }
}

export default AppHeader;
