import { MessagesAction } from '../actions';
import { IMessage } from '../chat/Chat';
import * as actionType from '../constants/actionType';

export interface IMessagesState {
  [messageId: string]: IMessage | undefined;
}

export interface IRoomMessagesState {
  [roomId: string]: IMessagesState | undefined;
}

export function roomMessages(state: IRoomMessagesState = {}, action: MessagesAction): IRoomMessagesState {
  const messages = state[action.roomId];

  switch(action.type) {
    case actionType.SYNC_ROOM_MESSAGES_SUCCESS:
      return { ...state, [action.roomId]: action.messages };
    case actionType.ADD_ROOM_MESSAGE_SUCCESS:
      return { ...state, [action.roomId]: { ...messages, [action.message.id]: action.message } };
    case actionType.UPDATE_ROOM_MESSAGE_SUCCESS:
      return { ...state, [action.roomId]: { ...messages, [action.messageId]: action.message } }
    case actionType.DELETE_ROOM_MESSAGE_SUCCESS:
      const messagesCopy = { ...messages };
      delete messagesCopy[action.messageId];
      return { ...state, [action.roomId]: messagesCopy };
    default:
      return state;
  }
}