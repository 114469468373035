import { EventEmitter } from 'events';

export enum EventType {
  ROOMS_SYNC = 'rooms-sync',
  ROOM_ADD = 'room-add',
  ROOM_UPDATE = 'room-update',
  ROOM_DELETE = 'room-delete',
  ROOM_ENTER = 'room-enter',
  ROOM_EXIT = 'room-exit',
  ROOM_INVITATION_REQUEST = 'room-invitation-request',
  ROOM_INVITATION_RESPONSE = 'room-invitation-response',

  ROOM_MESSAGES_SYNC = 'room-messages-sync',
  ROOM_MESSAGE_ADD = 'room-message-add',
  ROOM_MESSAGE_UPDATE = 'room-message-update',
  ROOM_MESSAGE_DELETE = 'room-message-delete',

  ROOM_USERS_SYNC = 'room-users-sync',
  ROOM_USER_ADD = 'room-user-add',
  ROOM_USER_DELETE = 'room-user-delete',

  USERS_ONLINE_SYNC = 'users-online-sync',
  USER_ONLINE_ADD = 'user-online-add',
  USER_ONLINE_DELETE = 'user-online-delete',

  AUTH_REQUIRED = 'auth-required',
  USER_UPDATE = 'user-update',

  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum MessageType {
  USER = 'user',
  SYSTEM_ENTER_ROOM = 'system-enter-room',
  SYSTEM_EXIT_ROOM = 'system-exit-room'
}

export interface IRoom {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
  createdByUser?: Pick<IUser, 'id' | 'name' | 'avatarName' | 'avatarUrl'>;
  createdAt?: number;
  updatedAt?: number;
  lastMessage?: Pick<IMessage, 'content' | 'fromUser' | 'type'>;
}

export interface IMessage {
  id: string;
  type: MessageType;
  content: string;
  fromUser?: Pick<IUser, 'id' | 'name' | 'avatarName' | 'avatarUrl'>;
  createdAt?: number;
  updatedAt?: number;
}

export interface IUser {
  id: string;
  name: string;
  avatarName?: string;
  avatarUrl?: string;
}

export default abstract class Chat {

  public user: IUser | null = null;

  protected eventEmitter: EventEmitter = new EventEmitter();

  public once(eventType: string, listener: (data: any) => void): Chat {
    this.eventEmitter.once(eventType, listener);
    return this;
  }

  public on(eventType: string, listener: (data: any) => void): Chat {
    this.eventEmitter.on(eventType, listener);
    return this;
  }

  public off(eventType: string, listener: (data: any) => void): Chat {
    this.eventEmitter.removeListener(eventType, listener);
    return this;
  }

  public addListener(eventType: string, listener: (data: any) => void): Chat {
    return this.on(eventType, listener);
  }

  public removeListener(eventType: string, listener: (data: any) => void): Chat {
    return this.off(eventType, listener);
  }

  public abstract syncUsersOnline(): Promise<{ [index: string]: IUser | undefined }>;

  public abstract stopSyncingUsersOnline(): Chat;

  public abstract syncRooms(): Promise<{ [index: string]: IRoom | undefined }>;

  public abstract stopSyncingRooms(): Chat;

  public abstract getRoom(roomId: string): Promise<IRoom | null>;

  public abstract addRoom(room: Partial<IRoom>): Promise<IRoom>;

  public abstract updateRoom(
    roomId: string,
    propertiesToUpdate: Partial<{ [P in keyof IRoom]: IRoom[P] | null }>
  ): Promise<IRoom>;

  public abstract deleteRoom(roomId: string): Promise<void>;

  public abstract enterRoom(roomId: string): Promise<void>;

  public abstract exitRoom(roomId: string): Promise<void>;

  public abstract inviteUserToRoom(): Promise<void>;

  public abstract acceptInvitationToRoom(): Promise<void>;

  public abstract syncRoomMessages(roomId: string): Promise<{ [index: string]: IMessage | undefined }>;

  public abstract stopSyncingRoomMessages(roomId: string): Chat;

  public abstract sendMessage(
    roomId: string,
    content: string,
    type: MessageType,
    onBeforeSend?: (messageId: string) => void 
  ): Promise<IMessage>;

  public abstract updateMessage(messageId: string, messageContent: string): Promise<IMessage>;

  public abstract deleteMessage(messageId: string): Promise<void>;

  public abstract syncRoomUsers(roomId: string): Promise<{ [index: string]: IUser | undefined }>;

  public abstract stopSyncingRoomUsers(roomId: string): Chat;

  public abstract updateUser(userProperties: Partial<IUser>): Promise<void>;

  public abstract setUser(user: IUser): Promise<void>;

  public abstract unsetUser(): Promise<void>;

}