import React from 'react';
import { IRoom } from '../chat/Chat';
import { ErrorType } from '../constants/error';
import ErrorMessage from './ErrorMessage';

export interface IProps {
  roomProperties: Partial<IRoom>;
  error?: ErrorType;
  isSubmitting: boolean;
  onInit: () => void;
  onInputChange: (roomProperties: Partial<IRoom>) => void;
  onSubmit: (roomProperties: Partial<IRoom>) => void;
}

class RoomForm extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    props.onInit();
  }

  public render() {
    const { error, isSubmitting, roomProperties } = this.props;

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return (
      <form noValidate={true} onSubmit={this.submit}>
        <div className="form-group">
          <label htmlFor="room-form-field-name">Name</label>
          <input
            id="room-form-field-name"
            className="form-control"
            type="text"
            required={true}
            name="name"
            value={roomProperties.name}
            onChange={this.handleInputChange}
          />
          <div className="invalid-feedback">
            Please provide a room name.
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="room-form-field-description">Description</label>
          <textarea
            id="room-form-field-description"
            className="form-control"
            rows={3}
            name="description"
            value={roomProperties.description}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="room-form-field-image-url">Image URL</label>
          <input
            id="room-form-field-image-url"
            className="form-control"
            type="url"
            name="imageUrl"
            value={roomProperties.imageUrl}
            onChange={this.handleInputChange}
          />
          <div className="invalid-feedback">
            Please provide a valid image url.
          </div>
        </div>
        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>Save</button>
      </form>
    );
  }

  public submit(e: React.FormEvent<HTMLFormElement>) {
    const form = e.currentTarget;
    const valid = form.checkValidity();

    form.classList.add('was-validated');

    e.preventDefault();
    e.stopPropagation();

    if (valid) {
      this.props.onSubmit(this.props.roomProperties);
    }
  }

  public handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const target = e.target;
    const name = target.name;
    let value: string | boolean = '';

    if (target instanceof HTMLInputElement) {
      value = target.type === 'checkbox' ? target.checked : target.value;
    } else {
      value = target.value;
    }

    this.props.onInputChange({ [name]: value });
  }
}

export default RoomForm;