export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export type CHANGE_ROUTE = typeof CHANGE_ROUTE;

export const GO_ONLINE = 'GO_ONLINE';
export type GO_ONLINE = typeof GO_ONLINE;

export const GO_OFFLINE = 'GO_OFFLINE';
export type GO_OFFLINE = typeof GO_OFFLINE;

export const GOOGLE_AUTH_REQUEST = 'GOOGLE_AUTH_REQUEST';
export type GOOGLE_AUTH_REQUEST = typeof GOOGLE_AUTH_REQUEST;

export const GET_REDIRECT_RESULT_REQUEST = 'GET_REDIRECT_RESULT_REQUEST';
export type GET_REDIRECT_RESULT_REQUEST = typeof GET_REDIRECT_RESULT_REQUEST;

export const SEND_EMAIL_LINK_REQUEST = 'SEND_EMAIL_LINK_REQUEST'
export type SEND_EMAIL_LINK_REQUEST = typeof SEND_EMAIL_LINK_REQUEST;

export const SEND_EMAIL_LINK_SUCCESS = 'SEND_EMAIL_LINK_SUCCESS';
export type SEND_EMAIL_LINK_SUCCESS = typeof SEND_EMAIL_LINK_SUCCESS;

export const EMAIL_LINK_AUTH_REQUEST = 'EMAIL_LINK_AUTH_REQUEST';
export type EMAIL_LINK_AUTH_REQUEST = typeof EMAIL_LINK_AUTH_REQUEST;

export const UNAUTH_REQUEST = 'UNAUTH_REQUEST';
export type UNAUTH_REQUEST = typeof UNAUTH_REQUEST;

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export type AUTH_SUCCESS = typeof AUTH_SUCCESS;

export const AUTH_FAILURE = 'AUTH_FAILURE';
export type AUTH_FAILURE = typeof AUTH_FAILURE;

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export type GET_CURRENT_USER_REQUEST = typeof GET_CURRENT_USER_REQUEST;

export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export type GET_CURRENT_USER_SUCCESS = typeof GET_CURRENT_USER_SUCCESS;

export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';
export type GET_CURRENT_USER_FAILURE = typeof GET_CURRENT_USER_FAILURE;

export const UPDATE_CURRENT_USER_REQUEST = 'UPDATE_CURRENT_USER_REQUEST';
export type UPDATE_CURRENT_USER_REQUEST = typeof UPDATE_CURRENT_USER_REQUEST;

export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export type UPDATE_CURRENT_USER_SUCCESS = typeof UPDATE_CURRENT_USER_SUCCESS;

export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE';
export type UPDATE_CURRENT_USER_FAILURE = typeof UPDATE_CURRENT_USER_FAILURE;

export const INIT_USER_FORM = 'INIT_USER_FORM';
export type INIT_USER_FORM = typeof INIT_USER_FORM;

export const CHANGE_USER_FORM = 'CHANGE_USER_FORM';
export type CHANGE_USER_FORM = typeof CHANGE_USER_FORM;

export const SYNC_USERS_ONLINE_REQUEST = 'SYNC_USERS_ONLINE_REQUEST';
export type SYNC_USERS_ONLINE_REQUEST = typeof SYNC_USERS_ONLINE_REQUEST;

export const SYNC_USERS_ONLINE_SUCCESS = 'SYNC_USERS_ONLINE_SUCCESS';
export type SYNC_USERS_ONLINE_SUCCESS = typeof SYNC_USERS_ONLINE_SUCCESS;

export const SYNC_USERS_ONLINE_FAILURE = 'SYNC_USERS_ONLINE_FAILURE';
export type SYNC_USERS_ONLINE_FAILURE = typeof SYNC_USERS_ONLINE_FAILURE;

export const STOP_SYNCING_USERS_ONLINE_REQUEST = 'STOP_SYNCING_USERS_ONLINE_REQUEST';
export type STOP_SYNCING_USERS_ONLINE_REQUEST = typeof STOP_SYNCING_USERS_ONLINE_REQUEST;

export const ADD_USER_ONLINE_SUCCESS = 'ADD_USER_ONLINE_SUCCESS';
export type ADD_USER_ONLINE_SUCCESS = typeof ADD_USER_ONLINE_SUCCESS;

export const DELETE_USER_ONLINE_SUCCESS = 'DELETE_USER_ONLINE_SUCCESS';
export type DELETE_USER_ONLINE_SUCCESS = typeof DELETE_USER_ONLINE_SUCCESS;

export const SYNC_ROOM_USERS_REQUEST = 'SYNC_ROOM_USERS_REQUEST';
export type SYNC_ROOM_USERS_REQUEST = typeof SYNC_ROOM_USERS_REQUEST;

export const SYNC_ROOM_USERS_SUCCESS = 'SYNC_ROOM_USERS_SUCCESS';
export type SYNC_ROOM_USERS_SUCCESS = typeof SYNC_ROOM_USERS_SUCCESS;

export const SYNC_ROOM_USERS_FAILURE = 'SYNC_ROOM_USERS_FAILURE';
export type SYNC_ROOM_USERS_FAILURE = typeof SYNC_ROOM_USERS_FAILURE;

export const STOP_SYNCING_ROOM_USERS_REQUEST = 'STOP_SYNCING_ROOM_USERS_REQUEST';
export type STOP_SYNCING_ROOM_USERS_REQUEST = typeof STOP_SYNCING_ROOM_USERS_REQUEST;

export const ADD_ROOM_USER_SUCCESS = 'ADD_ROOM_USER_SUCCESS';
export type ADD_ROOM_USER_SUCCESS = typeof ADD_ROOM_USER_SUCCESS;

export const OPEN_ROOM_USERS_MODAL = 'OPEN_ROOM_USERS_MODAL';
export type OPEN_ROOM_USERS_MODAL = typeof OPEN_ROOM_USERS_MODAL;

export const CLOSE_ROOM_USERS_MODAL = 'CLOSE_ROOM_USERS_MODAL';
export type CLOSE_ROOM_USERS_MODAL = typeof CLOSE_ROOM_USERS_MODAL;

export const DELETE_ROOM_USER_SUCCESS = 'DELETE_ROOM_USER_SUCCESS';
export type DELETE_ROOM_USER_SUCCESS = typeof DELETE_ROOM_USER_SUCCESS;

export const SYNC_ROOMS_REQUEST = 'SYNC_ROOMS_REQUEST';
export type SYNC_ROOMS_REQUEST = typeof SYNC_ROOMS_REQUEST;

export const SYNC_ROOMS_SUCCESS = 'SYNC_ROOMS_SUCCESS';
export type SYNC_ROOMS_SUCCESS = typeof SYNC_ROOMS_SUCCESS;

export const SYNC_ROOMS_FAILURE = 'SYNC_ROOMS_FAILURE';
export type SYNC_ROOMS_FAILURE = typeof SYNC_ROOMS_FAILURE;

export const STOP_SYNCING_ROOMS_REQUEST = 'STOP_SYNCING_ROOMS_REQUEST';
export type STOP_SYNCING_ROOMS_REQUEST = typeof STOP_SYNCING_ROOMS_REQUEST;

export const GET_ROOM_REQUEST = 'GET_ROOM_REQUEST';
export type GET_ROOM_REQUEST = typeof GET_ROOM_REQUEST;

export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS';
export type GET_ROOM_SUCCESS = typeof GET_ROOM_SUCCESS;

export const GET_ROOM_FAILURE = 'GET_ROOM_FAILURE';
export type GET_ROOM_FAILURE = typeof GET_ROOM_FAILURE;

export const ADD_ROOM_REQUEST = 'ADD_ROOM_REQUEST';
export type  ADD_ROOM_REQUEST = typeof ADD_ROOM_REQUEST;

export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export type ADD_ROOM_SUCCESS = typeof ADD_ROOM_SUCCESS;

export const ADD_ROOM_FAILURE = 'ADD_ROOM_FAILURE';
export type ADD_ROOM_FAILURE = typeof ADD_ROOM_FAILURE;

export const UPDATE_ROOM_REQUEST = 'UPDATE_ROOM_REQUEST';
export type UPDATE_ROOM_REQUEST = typeof UPDATE_ROOM_REQUEST;

export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export type UPDATE_ROOM_SUCCESS = typeof UPDATE_ROOM_SUCCESS;

export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE';
export type UPDATE_ROOM_FAILURE = typeof UPDATE_ROOM_FAILURE;

export const DELETE_ROOM_REQUEST = 'DELETE_ROOM_REQUEST';
export type DELETE_ROOM_REQUEST = typeof DELETE_ROOM_REQUEST;

export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export type DELETE_ROOM_SUCCESS = typeof DELETE_ROOM_SUCCESS;

export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';
export type DELETE_ROOM_FAILURE = typeof DELETE_ROOM_FAILURE;

export const INIT_ROOM_FORM = 'INIT_ROOM_FORM';
export type INIT_ROOM_FORM = typeof INIT_ROOM_FORM;

export const CHANGE_ROOM_FORM = 'CHANGE_ROOM_FORM';
export type CHANGE_ROOM_FORM = typeof CHANGE_ROOM_FORM;

export const OPEN_DELETE_ROOM_MODAL = 'OPEN_DELETE_ROOM_MODAL';
export type OPEN_DELETE_ROOM_MODAL = typeof OPEN_DELETE_ROOM_MODAL;

export const CLOSE_DELETE_ROOM_MODAL = 'CLOSE_DELETE_ROOM_MODAL';
export type CLOSE_DELETE_ROOM_MODAL = typeof CLOSE_DELETE_ROOM_MODAL;

export const ENTER_ROOM_REQUEST = 'ENTER_ROOM_REQUEST';
export type ENTER_ROOM_REQUEST = typeof ENTER_ROOM_REQUEST;

export const ENTER_ROOM_SUCCESS = 'ENTER_ROOM_SUCCESS';
export type ENTER_ROOM_SUCCESS = typeof ENTER_ROOM_SUCCESS;

export const ENTER_ROOM_FAILURE = 'ENTER_ROOM_FAILURE';
export type ENTER_ROOM_FAILURE = typeof ENTER_ROOM_FAILURE;

export const EXIT_ROOM_REQUEST = 'EXIT_ROOM_REQUEST';
export type EXIT_ROOM_REQUEST = typeof EXIT_ROOM_REQUEST;

export const EXIT_ROOM_SUCCESS = 'EXIT_ROOM_SUCCESS';
export type EXIT_ROOM_SUCCESS = typeof EXIT_ROOM_SUCCESS;

export const EXIT_ROOM_FAILURE = 'EXIT_ROOM_FAILURE';
export type EXIT_ROOM_FAILURE = typeof EXIT_ROOM_FAILURE;

export const UPDATE_CHAT_MESSAGE_DRAFT = 'UPDATE_CHAT_MESSAGE_DRAFT';
export type UPDATE_CHAT_MESSAGE_DRAFT = typeof UPDATE_CHAT_MESSAGE_DRAFT;

export const SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST';
export type SEND_CHAT_MESSAGE_REQUEST = typeof SEND_CHAT_MESSAGE_REQUEST;

export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS';
export type SEND_CHAT_MESSAGE_SUCCESS = typeof SEND_CHAT_MESSAGE_SUCCESS;

export const SYNC_ROOM_MESSAGES_REQUEST = 'SYNC_ROOM_MESSAGES_REQUEST';
export type SYNC_ROOM_MESSAGES_REQUEST = typeof SYNC_ROOM_MESSAGES_REQUEST;

export const SYNC_ROOM_MESSAGES_SUCCESS = 'SYNC_ROOM_MESSAGES_SUCCESS';
export type SYNC_ROOM_MESSAGES_SUCCESS = typeof SYNC_ROOM_MESSAGES_SUCCESS;

export const SYNC_ROOM_MESSAGES_FAILURE = 'SYNC_ROOM_MESSAGES_FAILURE';
export type SYNC_ROOM_MESSAGES_FAILURE = typeof SYNC_ROOM_MESSAGES_FAILURE;

export const ADD_ROOM_MESSAGE_SUCCESS = 'ADD_ROOM_MESSAGE_SUCCESS';
export type ADD_ROOM_MESSAGE_SUCCESS = typeof ADD_ROOM_MESSAGE_SUCCESS;

export const UPDATE_ROOM_MESSAGE_SUCCESS = 'UPDATE_ROOM_MESSAGE_SUCCESS';
export type UPDATE_ROOM_MESSAGE_SUCCESS = typeof UPDATE_ROOM_MESSAGE_SUCCESS;

export const DELETE_ROOM_MESSAGE_SUCCESS = 'DELETE_ROOM_MESSAGE_SUCCESS';
export type DELETE_ROOM_MESSAGE_SUCCESS = typeof DELETE_ROOM_MESSAGE_SUCCESS;