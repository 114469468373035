import { IMessage } from '../chat/Chat';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';
import { IMessagesState } from '../reducers/roomMessages';

export interface ISyncRoomMessagesRequest {
  type: actionType.SYNC_ROOM_MESSAGES_REQUEST;
  roomId: string;
}

export interface ISyncRoomMessagesSuccess {
  type: actionType.SYNC_ROOM_MESSAGES_SUCCESS;
  roomId: string;
  messages: IMessagesState;
}

export interface ISyncRoomMessagesFailure {
  type: actionType.SYNC_ROOM_MESSAGES_FAILURE;
  roomId: string;
  error: ErrorType;
}

export interface IAddRoomMessageSuccess {
  type: actionType.ADD_ROOM_MESSAGE_SUCCESS;
  roomId: string;
  message: IMessage;
}

export interface IUpdateRoomMessageSuccess {
  type: actionType.UPDATE_ROOM_MESSAGE_SUCCESS;
  roomId: string;
  messageId: string;
  message: IMessage;
}

export interface IDeleteRoomMessageSuccess {
  type: actionType.DELETE_ROOM_MESSAGE_SUCCESS
  roomId: string;
  messageId: string;
}

export type MessagesAction =
  ISyncRoomMessagesRequest | ISyncRoomMessagesSuccess | ISyncRoomMessagesFailure |
  IAddRoomMessageSuccess |
  IUpdateRoomMessageSuccess |
  IDeleteRoomMessageSuccess;

export function syncRoomMessagesRequest(roomId: string): ISyncRoomMessagesRequest {
  return {
    roomId,
    type: actionType.SYNC_ROOM_MESSAGES_REQUEST
  };
}

export function syncRoomMessagesSuccess(roomId: string, messages: IMessagesState): ISyncRoomMessagesSuccess {
  return {
    messages,
    roomId,
    type: actionType.SYNC_ROOM_MESSAGES_SUCCESS
  };
}

export function syncRoomMessagesFailure(roomId: string, error: ErrorType): ISyncRoomMessagesFailure {
  return {
    error,
    roomId,
    type: actionType.SYNC_ROOM_MESSAGES_FAILURE
  };
}

export function addRoomMessageSuccess(roomId: string, message: IMessage): IAddRoomMessageSuccess {
  return {
    message,
    roomId,
    type: actionType.ADD_ROOM_MESSAGE_SUCCESS
  };
}

export function updateRoomMessageSuccess(roomId: string, messageId: string, message: IMessage): IUpdateRoomMessageSuccess {
  return {
    message,
    messageId,
    roomId,
    type: actionType.UPDATE_ROOM_MESSAGE_SUCCESS
  };
}

export function deleteRoomMessageSuccess(roomId: string, messageId: string): IDeleteRoomMessageSuccess {
  return {
    messageId,
    roomId,
    type: actionType.DELETE_ROOM_MESSAGE_SUCCESS
  };
}