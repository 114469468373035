import {
  AuthAction,
  DeleteRoomAction,
  DeleteRoomModalAction,
  ISendChatMessageRequest,
  ISendChatMessageSuccess,
  MessagesAction,
  RoomsAction,
  RoomUsersAction,
  RoomUsersModalAction,
  SyncRoomsAction,
  UsersAction,
  UsersOnlineAction
} from '../actions';
import * as actionType from '../constants/actionType';
import { ErrorType } from '../constants/error';

export interface IRoomListUiState {
  isLoading: boolean;
  error?: ErrorType;
}

export function roomListUi(
  state: IRoomListUiState = { isLoading: false },
  action: SyncRoomsAction
): IRoomListUiState {

  switch (action.type) {
    case actionType.SYNC_ROOMS_REQUEST:
      return { isLoading: true };
    case actionType.SYNC_ROOMS_SUCCESS:
      return { isLoading: false };
    case actionType.SYNC_ROOMS_FAILURE:
      return { isLoading: false, error: action.error };
    default:
      return state;
  }
}

export interface IMessageListUiState {
  isLoading: boolean;
  error?: ErrorType;
}

export interface IRoomMessageListUiState {
  [roomId: string]: IMessageListUiState | undefined;
}

export function roomMessageListUi(state: IRoomMessageListUiState = {}, action: MessagesAction): IRoomMessageListUiState {
  switch(action.type) {
    case actionType.SYNC_ROOM_MESSAGES_REQUEST:
      return { ...state, [action.roomId]: { isLoading: true } };
    case actionType.SYNC_ROOM_MESSAGES_SUCCESS:
      return { ...state, [action.roomId]: { isLoading: false } };
    case actionType.SYNC_ROOM_MESSAGES_FAILURE:
      return { ...state, [action.roomId]: { isLoading: false, error: action.error } };
    default:
      return state;
  }
}

export interface IRoomUiState {
  chatMessageDraft: string;
  error?: ErrorType
  inRoom: boolean;
  isEntering: boolean;
  isExiting: boolean;
  isLoading: boolean;
}

export interface IRoomsUiState {
  [roomId: string]: IRoomUiState | undefined;
}

export const defaultRoomUiState: IRoomUiState = {
  chatMessageDraft: '',
  inRoom: false,
  isEntering: false,
  isExiting: false,
  isLoading: false
}

export function roomsUi(state: IRoomsUiState = {}, action: RoomsAction): IRoomsUiState {
  let roomId: string;
  let roomUi: IRoomUiState | undefined;

  switch(action.type) {
    case actionType.ENTER_ROOM_REQUEST:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isEntering: true } };
    case actionType.ENTER_ROOM_SUCCESS:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isEntering: false, inRoom: true } };
    case actionType.ENTER_ROOM_FAILURE:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isEntering: false, inRoom: false, error: action.error } };
    case actionType.EXIT_ROOM_REQUEST:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isExiting: true } };
    case actionType.EXIT_ROOM_SUCCESS:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isExiting: false, inRoom: false } };
    case actionType.EXIT_ROOM_FAILURE:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isExiting: false, error: action.error } };
    case actionType.GET_ROOM_REQUEST:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isLoading: true } };
    case actionType.GET_ROOM_SUCCESS:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isLoading: false } };
    case actionType.GET_ROOM_FAILURE:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, isLoading: false, error: action.error } };
    case actionType.UPDATE_CHAT_MESSAGE_DRAFT:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, chatMessageDraft: action.chatMessageDraft } };
    case actionType.SEND_CHAT_MESSAGE_REQUEST:
      roomId = action.roomId;
      roomUi = state[roomId] || defaultRoomUiState;
      return { ...state, [roomId]: { ...roomUi, chatMessageDraft: '' } };
    default:
      return state;
  }
}

export interface IMessageUiState { isSending: boolean }

export interface IRoomMessagesUiState {
  [roomId: string]: { [messageId: string]: IMessageUiState | undefined } | undefined;
}

export function roomMessagesUi(
  state: IRoomMessagesUiState = {},
  action: ISendChatMessageRequest | ISendChatMessageSuccess
): IRoomMessagesUiState {
  const roomId = action.roomId;
  const messageId = action.messageId;
  const messagesUi = state[roomId] || {};

  switch (action.type) {
    case actionType.SEND_CHAT_MESSAGE_REQUEST:
      return { ...state, [roomId]: { ...messagesUi, [messageId]: { isSending: true } } };
    case actionType.SEND_CHAT_MESSAGE_SUCCESS:
      return { ...state, [roomId]: { ...messagesUi, [messageId]: { isSending: false } } };
    default:
      return state;
  }
}

export interface IDeleteRoomModalUiState {
  error?: ErrorType;
  roomId?: string;
  isOpen: boolean;
  isLoading: boolean;
}

export function deleteRoomModalUi(
  state: IDeleteRoomModalUiState = { isOpen: false, isLoading: false },
  action: DeleteRoomModalAction | DeleteRoomAction
): IDeleteRoomModalUiState {

  switch (action.type) {
    case actionType.OPEN_DELETE_ROOM_MODAL:
      return { ...state, isOpen: true, roomId: action.roomId };
    case actionType.CLOSE_DELETE_ROOM_MODAL:
      return {
        isLoading: state.isLoading,
        isOpen: false
      };
    case actionType.DELETE_ROOM_REQUEST:
      if (action.roomId === state.roomId) {
        return { ...state, isLoading: true };
      } else {
        return state;
      }
    case actionType.DELETE_ROOM_SUCCESS:
      if (action.roomId === state.roomId) {
        return { ...state, isLoading: false };
      } else {
        return state;
      }
    case actionType.DELETE_ROOM_FAILURE:
      if (action.roomId === state.roomId) {
        return { ...state, isLoading: false, error: action.error };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export interface ICurrentUserUiState {
  error?: ErrorType;
  emailLinkSent?: string;
  isLoadingUserCredential: boolean;
  isLoadingUserData: boolean;
}

export const defaultCurrentUserUiState: ICurrentUserUiState = {
  isLoadingUserCredential: false,
  isLoadingUserData: false
}

export function currentUserUi(
  state: ICurrentUserUiState = defaultCurrentUserUiState,
  action: UsersAction | AuthAction
): ICurrentUserUiState {

  switch (action.type) {
    case actionType.GOOGLE_AUTH_REQUEST:
    case actionType.GET_REDIRECT_RESULT_REQUEST:
    case actionType.SEND_EMAIL_LINK_REQUEST:
    case actionType.EMAIL_LINK_AUTH_REQUEST:
    case actionType.UNAUTH_REQUEST:
      return { ...state, isLoadingUserCredential: true };
    case actionType.SEND_EMAIL_LINK_SUCCESS:
      return { ...state, emailLinkSent: action.email };
    case actionType.AUTH_SUCCESS:
      return { ...state, isLoadingUserCredential: false };
    case actionType.AUTH_FAILURE:
      return { ...state, isLoadingUserCredential: false, error: action.error };
    case actionType.GET_CURRENT_USER_REQUEST:
      return { ...state, isLoadingUserData: true };
    case actionType.GET_CURRENT_USER_SUCCESS:
      return { ...state, isLoadingUserData: false };
    case actionType.GET_CURRENT_USER_FAILURE:
      return { ...state, isLoadingUserData: false, error: action.error };
    default:
      return state;
  }
}

export interface IUserOnlineListUiState {
  error?: ErrorType;
  isLoading: boolean;
}

export function userOnlineListUi(
  state: IUserOnlineListUiState = { isLoading: false },
  action: UsersOnlineAction
) {

  switch(action.type) {
    case actionType.SYNC_USERS_ONLINE_REQUEST:
      return { isLoading: true };
    case actionType.SYNC_USERS_ONLINE_SUCCESS:
      return { isLoading: false };
    case actionType.SYNC_USERS_ONLINE_FAILURE:
      return { error: action.error, isLoading: false };
    default:
      return state;
  }
}

export interface IUsersModalUiState {
  error?: ErrorType;
  isOpen: boolean;
  isLoading: boolean;
}

export interface IRoomUsersModalsUiState { [roomId: string]: IUsersModalUiState | undefined }

export const defaultRoomUsersModalState = { isOpen: false, isLoading: false };

export function roomUsersModalsUi(state: IRoomUsersModalsUiState = {}, action: RoomUsersAction | RoomUsersModalAction): IRoomUsersModalsUiState {
  const usersModalUi = state[action.roomId] || defaultRoomUsersModalState;

  switch(action.type) {
    case actionType.OPEN_ROOM_USERS_MODAL:
      return { [action.roomId]: { ...usersModalUi, isOpen: true } };
    case actionType.CLOSE_ROOM_USERS_MODAL:
      return { [action.roomId]: { ...usersModalUi, isOpen: false } };
    case actionType.SYNC_ROOM_USERS_REQUEST:
      return { [action.roomId]: { ...usersModalUi, isLoading: true } };
    case actionType.SYNC_ROOM_USERS_SUCCESS:
      return { [action.roomId]: { ...usersModalUi, isLoading: false } };
    case actionType.SYNC_ROOM_USERS_FAILURE:
      return { [action.roomId]: { ...usersModalUi, isLoading: false, error: action.error } };
    default:
      return state;
  }
}